import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CaracteristicaCatComponent } from './views/caracteristica/caracteristica-cat/caracteristica-cat.component';
import { CaracteristicaComponent } from './views/caracteristica/caracteristica/caracteristica.component';
import { CaracteristicaEquipoComponent } from './views/equipo/caracteristica-equipo/caracteristica-equipo.component';
import { EquipoComponent } from './views/equipo/equipo/equipo.component';
import { EstatusComponent } from './views/estatus/estatus/estatus.component';
import { HomeComponent } from './views/home/home.component';
import { MarcaComponent } from './views/marca/marca/marca.component';
import { ModeloComponent } from './views/modelo/modelo/modelo.component';
import { ParteCatComponent } from './views/parte/parte-cat/parte-cat.component';
import { ParteComponent } from './views/parte/parte/parte.component';
import { FallaComponent } from './views/solicitud/falla/falla/falla.component';
import { GrafDespMensualDashComponent } from './views/solicitud/graf-desp-mensual-dash/graf-desp-mensual-dash.component';
import { ReparacionComponent } from './views/solicitud/reparacion/reparacion/reparacion.component';
import { SolicitudComponent } from './views/solicitud/solicitud/solicitud.component';
import { SolicitudesNuevasDashComponent } from './views/solicitud/solicitudes-nuevas-dash/solicitudes-nuevas-dash.component';
import { TecnicoComponent } from './views/tecnico/tecnico/tecnico.component';
import { TipoEquipoComponent } from './views/tipo-equipo/tipo-equipo/tipo-equipo.component';
import { TipoSolicitudComponent } from './views/tipo-solicitud/tipo-solicitud/tipo-solicitud.component';

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "estatus", component: EstatusComponent },
  { path: "marca", component: MarcaComponent },
  { path: "modelo", component: ModeloComponent },
  { path: "parte", component: ParteComponent },
  { path: "tiposol", component: TipoSolicitudComponent },
  { path: "equipo", component: EquipoComponent },
  { path: "dashboard/solicitud", component: SolicitudesNuevasDashComponent },
  { path: "dashboard/solmens", component: GrafDespMensualDashComponent },
  { path: "solicitud", component: SolicitudComponent },
  { path: "solicitud-tec", component: SolicitudComponent },
  { path: "tipo-equipo", component: TipoEquipoComponent },
  { path: "falla", component: FallaComponent },
  {
    path: "cat-test", component: ParteCatComponent,
    data: { 'addNewEnable': 'true', 'idTipoEquipo': 1 }
  },
  { path: "reparacion", component: ReparacionComponent },
  { path: "caracteristica", component: CaracteristicaComponent },
  {
    path: "caract-test", component: CaracteristicaCatComponent,
    data: { 'addNewEnable': 'true' }
  },
  { path: "caract-equipo", component: CaracteristicaEquipoComponent },
  { path: 'tecnico', component: TecnicoComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
