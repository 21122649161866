import { Component, OnInit } from '@angular/core';

import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ButtonModel } from '@axks/components/lib/api/views/button-model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Marca } from '../../../api/marca';
import { MarcaPsService } from '../../../procs/marca/marca-ps.service';


@Component({
  selector: 'axks-marca',
  templateUrl: './marca.component.html',
  styleUrls: ['./marca.component.scss'],
  providers: [MessageService]
})
export class MarcaComponent extends BaseMainComponent<MarcaPsService> implements OnInit {

  extraButtons: ButtonModel[];

  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private psService: MarcaPsService) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    this.extraButtons = [];
    super.ngOnInit();
  }

  initEmptyItem(): ComponentItem {
    
    return {
      item: {tipoEquipo:{}} as Marca,
      minimumItem: {tipoEquipo:{}} as Marca,
      registerItem: {tipoEquipo:{}} as Marca
    } as ComponentItem;
  }

  getService(): MarcaPsService {
    return this.psService;
  }
  getColListDefinition(): ColumnDefinition[] {
    return [
      { header: 'Clave', field: 'idMarca', inDetail: true, inResultList: true },
      { header: 'Marca', field: 'marca', inAll: true },
      { header: 'Tipo de Equipo' , field:'tipoEquipo.tipoEquipo', inAll:true}


    ] as ColumnDefinition[];
  }
  getIdOfSelectedItem(): number {
    return this.item.minimumItem.idMarca || this.item.item.idMarca;
  }
  getComponentTitle(): string {
    return "Marcas";
  }
}