<p-dialog header="Recibir equipo" [(visible)]="display" [modal]=true [style]="dialogSize()"
    (onHide)="onHideDialog($event)">
    <div class="p-grid" [style]="gridSize()">

        <div class="p-col-12">
            <br>
            <span class="p-float-label">
                <textarea id="fl-notasrec" pInputTextarea [(ngModel)]="item.notasRecepcion" [rows]="3" [cols]="40"
                    [autoResize]="false" [style]="{'width':'90%'}"></textarea>
                <label for="fl-notasrec">Observaciones</label>
            </span>
        </div>

    </div>
    <ng-template pTemplate="footer">
        <p-button label="Recibir equipo" [loading]="isLoading" icon="fa fa-desktop"
            (onClick)="onRecibirEquipo($event)"></p-button>
        <p-button label="Cancelar" styleClass="p-button-danger" icon="fa fa-ban" [loading]="isLoading"
            (onClick)="onCancelar($event)"></p-button>
    </ng-template>
</p-dialog>