import { version } from '../../package.json';

export const environment = {
  version: version,
  production: false,
  dev: false,
  uat: true,

  appId: "soporte-gui",
  empresas: [
    { id: 1, domain: 'uat.axkansoluciones.com' },
    { id: 2, domain: 'uat.sbsynergy.mx' },
    { id: 3, domain: 'uat.habitataxkan.com.mx' }
  ],

  //gateway: "http://dev-ms.axkans.net/",
  sprofile: "https://sec.uat-ms.axkans.net/autentica/profile",
  msEstatus: "https://soporte.uat-ms.axkans.net/soporte/estatus",
  msMarca: "https://soporte.uat-ms.axkans.net/soporte/marca",
  msModelo: "https://soporte.uat-ms.axkans.net/soporte/modelo",
  msParte: "https://soporte.uat-ms.axkans.net/soporte/parte",
  msPuesto: "https://soporte.uat-ms.axkans.net/soporte/puesto",
  msTecnico: "https://soporte.uat-ms.axkans.net/soporte/tecnico",
  msTipoSolicitud: "https://soporte.uat-ms.axkans.net/soporte/tiposol",
  msEquipo: "https://soporte.uat-ms.axkans.net/soporte/equipo",
  msSolicitud: "https://soporte.uat-ms.axkans.net/soporte/solicitud",
  msTipoEquipo: "https://soporte.uat-ms.axkans.net/soporte/tipo-equipo",
  msAsignaEquipo: "https://soporte.uat-ms.axkans.net/soporte/equipo/asigna",
  msFalla: "https://soporte.uat-ms.axkans.net/soporte/falla",
  msDiagnostico: "https://soporte.uat-ms.axkans.net/soporte/diagnostico",
  msReparacion: "https://soporte.uat-ms.axkans.net/soporte/reparacion",
  msAtencion: "https://soporte.uat-ms.axkans.net/soporte/atencion",
  msEmpleado: "https://rh.uat-ms.axkans.org/rh/empleado/ps",
  msCaracteristica: "https://soporte.uat-ms.axkans.net/soporte/caracteristica",
  msEstado: "https://soporte.uat-ms.axkans.net/soporte/estado-equipo",
  theme: {
    default: 'https://static.uat-gui.axkans.org/portal/themes/blue-gray/styles/theme.css'
  }

};
