<p-card class="crd-accion-seg" *ngIf="idTipoSolicitud == TP_SOLICITUD.INSTALACION_SOFTWARE">
  <ng-container *ngIf="seguimiento.estatus.idEstatus == EST_SOLICITUD.LICENCIA_INSTALADA">
    <div class="p-grid">
      <div class="p-col-12">
        <p>
          Si las licencias y/o softwares se instalaron de manera correcta favor de finalizar la solicitud. Si no la
          finaliza, la
          solicitud
          se cerrara automaticamente una semana despues de ser atendida.
          En caso de requerir atención y si la solicitud ya se cerro, tendra que abrir otra solicitud.
        </p>
      </div>
    </div>

    <ng-template pTemplate="footer">
      <p-button label="Finalizar solicitud" icon="pi pi-check-circle" [loading]="isLoading"
        (onClick)="onLicenciaInstaladaAct($event)">
      </p-button>
    </ng-template>
  </ng-container>
</p-card>

<p-toast></p-toast>