import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LabelPsService {

  private _contentFile = "assets/content.json";

  labels = new BehaviorSubject<any>({});
  currentLabels: any;


  constructor(private httpClient: HttpClient) {
    this.processContent();
  }

  processContent() {
    this.httpClient.get(this._contentFile).subscribe(
      (data) => {
        this.labels.next(data);
        this.currentLabels = data;
      }
    )
  }

  getHelpByKey(key: string): string {
    if (this.currentLabels) {
      return this.currentLabels["help"][key];
    }
    return "";
  }
}
