import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Modelo } from 'src/app/api/modelo';

@Component({
  selector: 'axks-modelo-form',
  templateUrl: './modelo-form.component.html',
  styleUrls: ['./modelo-form.component.scss']
})
export class ModeloFormComponent extends BaseView implements OnInit {

  @Input() item: Modelo;
  @Input() operationMode: OperationMode;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {
        marca: {
          tipoEquipo: {}
        }
      } as Modelo;
    } else {
      if (!this.item.marca) {
        this.item.marca = {
          tipoEquipo: {}
        };
      }
    }
  }

}
