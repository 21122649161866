import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { TipoEquipoRestcService } from 'src/app/client/tipo-equipo/tipo-equipo-restc.service';

@Injectable({
  providedIn: 'root'
})
export class TipoEquipoPsService extends BaseProcess<TipoEquipoRestcService>{

  constructor(protected httpClient: HttpClient, 
    crest: TipoEquipoRestcService) { 
    super(httpClient, crest);
  }
}
