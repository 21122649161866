import { DatePipe, formatCurrency } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationMode, OperationScreenModeService, SessionClientProvider, TabDefinition } from '@axks/components';
import { ButtonModel } from '@axks/components/lib/api/views/button-model';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Equipo, EquipoReg } from '../../../api/equipo';
import { EmpleadoPsService } from 'src/app/procs/empleado/empleado-ps.service';
import { EquipoPsService } from '../../../procs/equipo/equipo-ps.service';
import { EstadoEquipoEnum } from '../../../api/enums/estado-equipo.enum';

/**
 * Main component for technical support device.
 * 
 * @author
 */
@Component({
  selector: 'axks-equipo',
  templateUrl: './equipo.component.html',
  styleUrls: ['./equipo.component.scss'],
  providers: [MessageService]
})
export class EquipoComponent extends BaseMainComponent<EquipoPsService> implements OnInit {

  extraButtons: ButtonModel[];

  showAsigna: boolean = false;

  showRecibe: boolean = false;

  MODE_ADD: OperationMode = OperationMode.ADDNEW;

  modoEdit: OperationMode = OperationMode.EDIT;

  showFindButton: boolean = true;

  showCardCaract: boolean = false;

  tabDefs: TabDefinition[];
  
  constructor(
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private psService: EquipoPsService
  ) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    this.showAsigna = false;
    super.ngOnInit();
    this.initButtonCaract();
    this.getTabs();
  }

  initEmptyItem(): ComponentItem {
    let profile = this.getProfile() as ProfileC;
    return {
      item: {
        modelo: { marca: { tipoEquipo: {} } },
        estado: {}
      } as Equipo,
      minimumItem: {
        modelo: { marca: { tipoEquipo: {} } },
        estado: {}
      } as Equipo,
      registerItem: {
        idEmpresa: profile.empresa,
        modelo: { marca: { tipoEquipo: {} } },
        estado: {},
        caracteristicas: [{}],
      } as EquipoReg
    }
  }

  getService(): EquipoPsService {
    return this.psService;
  }

  getTabs() {
    this.tabDefs = [
      {
        groupId: 'Caracteristicas del equipo',
        showAsTable: true
      }
    ] as TabDefinition[];
  }

  getColListDefinition(): ColumnDefinition[] {
    return [
      { header: 'No. Equipo', field: 'id', inAll: true },
      { header: 'Nombre', field: 'nombre', inAll: true },
      { header: 'No. Serie', field: 'numSerie', inAll: true },
      { header: 'Tipo de equipo', field: 'modelo.marca.tipoEquipo.tipoEquipo', inAll: true },
      { header: 'Marca', field: 'modelo.marca.marca', inAll: true },
      { header: 'Modelo', field: 'modelo.modelo', inAll: true },
      { header: 'Fecha de adquisición', field: 'fechaAdquisicion', inAll: true, formatFunction: this.formatDate },
      { header: 'Estado', field: 'estado.estadoEquipo', inAll: true },
      { header: 'Precio', field: 'costo', inDetail: true, formatFunction: this.formatoMoneda },
      { header: 'No. Compra', field: 'idCompra', inDetail: true },
      { header: 'Observaciones', field: 'comentario', inDetail: true },
      { header: 'Disponibilidad', field: 'disponibilidad', inResultList: true },
      { header: 'Asignado a', field: 'empleado', inAll: true },

      { header: 'Tipo de caracteristica', field: 'idCaracteristica.caracteristica', arrayFieldName: 'caracteristicas', inDetail: true, groupId: 'Caracteristicas del equipo' },
      { header: 'Valor', field: 'valor', arrayFieldName: 'caracteristicas', groupId: 'Caracteristicas del equipo', inDetail: true },
    ] as ColumnDefinition[];
  }
  getIdOfSelectedItem(): any {
    return this.item.minimumItem.id || this.item.item.id;
  }
  getComponentTitle(): string {
    return "Equipo";
  }

  formatoMoneda(value: number): string {
    return formatCurrency(value, "es-MX", "$ ", "MXN");
  }

  onAsignar = () => {
    if (this.workItem().estado.id == EstadoEquipoEnum.FUNCIONAL) {
      this.showAsigna = true;
    }
    else {
      this.errorMessage("Equipo en mantenimiento.", "No se puede asignar el equipo porque se encuentra en mantenimiento." +
        " Favor de ponerlo funcional para poder asignarlo.");
    }
  }

  onHideDialog(event) {
    this.rowSelect(this.item.minimumItem);
    this.showAsigna = false;
  }

  onRecibir = () => {
    this.showRecibe = true;

  }

  onHideDialogRecibir(event) {
    this.rowSelect(this.item.minimumItem);
    this.showRecibe = false;
  }

  rowSelect(event) {
    this.item.item = this.initEmptyItem().item;
    this.extraButtons = [];
    super.rowSelect(event);
    setTimeout(() => {
      let equipo = this.item.item as Equipo;

      if (!equipo.asignado == true) {
        this.initButtonsAsigna();

      } else {
        this.initButtonsRecibe();
      }

    }, 3000);
  }

  initButtonsAsigna() {
    this.extraButtons = [
      {
        name: 'btnAsignar', enable: true, label: 'Asignar Equipo', position: 'left', showLabel: true,
        showWhen: [OperationMode.SELECTED], onClick: this.onAsignar.bind(this)

      },
      {
        name: 'btnCaract', label: 'Caracteristicas del equipo', position: 'right', showLabel: true,
        showWhen: [this.modoEdit, this.MODE_ADD], onClick: this.onCaract.bind(this)
      }
    ] as ButtonModel[];
  }

  initButtonsRecibe() {
    this.extraButtons = [
      {
        name: 'btnRecibir', enable: true, label: 'Recibir Equipo', position: 'left', showLabel: true,
        showWhen: [OperationMode.SELECTED], onClick: this.onRecibir.bind(this)

      },
      {
        name: 'btnCaract', label: 'Caracteristicas del equipo', position: 'right', showLabel: true,
        showWhen: [this.modoEdit, this.MODE_ADD], onClick: this.onCaract.bind(this)
      }
    ] as ButtonModel[];
  }

  initButtonCaract() {
    this.extraButtons = [
      {
        name: 'btnCaract', enable: true, label: 'Caracteristicas del equipo', position: 'right', showLabel: true,
        showWhen: [OperationMode.ADDNEW, OperationMode.EDIT], onClick: this.onCaract.bind(this)
      }
    ] as ButtonModel[];
  }

  onCaract() {
    this.showCardCaract = true;
  }

  formatDate(date: string): string {
    let pipe = new DatePipe("es-MX");
    return pipe.transform(date, "dd 'de' MMMM 'de' yyyy");
  }

  componentsFields() {
    super.componentsFields();
    this.formFields = ["form-caracteristicas", "fechaAdquisicion", "noSerie", "modelo", "marca", "tipoEquipo", "comentario", "costo", "noCompra"];
    this.formSearch = ["noSerie", "modelo", "marca", "tipoEquipo", "empleados", "disponibilidad"];
  }


  onBuscaDisponibles(event) {
    this.showFindButton = false;
    if (event == true) {
      this.psService.equiposDisponibles().subscribe(
        (data) => {
          this.list.data = data;
          this.showFindButton = true;
        },
        (error) => {
          this.showFindButton = true;
        }
      )
    } else {
      this.list.data = [] as Equipo[];
      this.find({});
      this.showFindButton = true;
    }
  }

  addNew(event: any): void {
    this.initButtonCaract();
    super.addNew(event);
  }

  save(event: any): void {
    let caractValidas: boolean = true;
    if (this.workItem().caracteristicas.length > 0) {
      for (let carItem of this.workItem().caracteristicas) {
        if (!carItem.idCaracteristica || !carItem.idCaracteristica.id || !carItem.valor
          || carItem.valor.trim() == '') {
          caractValidas = false;
        }
      }

      if (caractValidas) {
        super.save(event);
      }
      else {
        this.infoMessage("Caracteristicas faltantes", "Revisar que las caracteristicas del equipo esten llenadas debidamente.")
      }
    }
    else {
      this.infoMessage("Caracteristicas faltantes", "Favor de agregar al menos una caracteristica del equipo a dar de alta.")
    }
  }

}