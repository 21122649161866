<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('marca')">
        <br>
        <span class="p-float-label">
            <input id="fl-nmmarca" type="text" pInputText [(ngModel)]="item.marca">
            <label for="fl-nmmarca">Marca</label>
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('marca')">
        <axks-tipo-equipo-cat [value]="item.tipoEquipo"></axks-tipo-equipo-cat>
    </div>
</div>