<br>
<span class="p-float-label">
    <p-button icon="pi pi-plus" *ngIf="addNewEnable && !showForm" (click)="onAddNewClick($event)" class="input-button">
    </p-button>
    <p-dropdown inputId="fl-marca" [(ngModel)]="value.idMarca" [options]="catalog" optionLabel="marca"
        optionValue="idMarca" [autoDisplayFirst]="false" [virtualScroll]="virtualScroll" itemSize="10" [filter]="true"
        filterBy="marca" autoWidth="false" (onChange)="selectOption($event, value)"
        [styleClass]="addNewEnable && !showForm ? 'with-button':''"></p-dropdown>
    <label for="fl-marca">Marca</label>
</span>

<p-dialog [(visible)]="showForm" header="Nueva marca" [modal]="true" [draggable]="false" [closable]="true"
    contentStyleClass="dialog-content-style" [style]="{'width':'50%', 'height':'60%'}">

    <axks-marca-form [item]="newItem" [formFields]="[]" [operationMode]="operationMode" [class]="'cmp-form-' + 1">
    </axks-marca-form>
    <ng-template pTemplate="footer">

        <p-button label="Guardar" icon="pi pi-save" iconPos="left" (click)="onSaveClick($event)"></p-button>
        &nbsp;
        <p-button label="Cancelar" icon="pi pi-times" iconPos="left" (click)="onCancelClick($event)"></p-button>
    </ng-template>
</p-dialog>

<p-toast></p-toast>