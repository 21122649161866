<p-card class="crd-accion-seg" *ngIf="idTipoSolicitud == tipoSolicitud.ALTA_CORREO_ELECTRONICO">

	<!-- INGRESADA Y/O RECIBIDA -->
	<ng-container
		*ngIf="seguimiento.estatus.idEstatus == ESTATUS.INGRESADA || seguimiento.estatus.idEstatus == ESTATUS.RECIBIDA">
		<div class="p-grid">
			<div class="p-col-12">
				<span> Notificar al empleado solicitante cuando se haya creado el correo electronico.</span>
			</div>
		</div>
		<ng-template pTemplate="footer">
			<p-button label="Correo generado" icon="pi pi-envelope" [loading]="isLoading" (onClick)="onSolicitudRecibidaAct($event)"></p-button>
		</ng-template>
	</ng-container>

</p-card>