import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { Observable } from 'rxjs';
import { AsignaEquipo, RecepcionEquipo } from 'src/app/api/equipo';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AsignaRestcService extends ARestClient{
  

  constructor(httpClient: HttpClient) { 
    super(httpClient)
  }

  getBaseEndpoint(): string {
   return environment.msAsignaEquipo+"/";
  }
  processBody(res: any) {
    return res || {};
  }

  getRestPaths(): RestBasicPaths {
    let paths = super.getRestPaths();
    paths["recepcion"] = "recibe";

    return paths;
  }

  recibeEquipo(recEquipo: RecepcionEquipo): Observable<AsignaEquipo>{
    let path = this.getBaseEndpoint() + this.getRestPaths()["recepcion"];

        if (!path.endsWith("/")) {
            path += "/"
        }
        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers', "*");
        return this.httpClient.post(path,recEquipo, { headers }) as Observable<AsignaEquipo>;
  }
}
