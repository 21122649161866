<div class="p-grid frm-part">
    <!-- {{item | json}} -->
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('empleados')">
        <span class="p-float-label">
            <p-dropdown inputId="dropdown" [autoDisplayFirst]="false" [options]="empleados" optionValue="id"
                [filter]="true" filterBy="nombreCompleto" [(ngModel)]="item.idEmpleado" optionLabel="nombreCompleto"
                [style]="{'width': '80%'}"></p-dropdown>
            <label for="dropdown">Empleados</label>
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('fecAsigna')">
        <span class="p-float-label">
            <p-calendar inputId="calendar" [(ngModel)]="item.fecAsigna" [showIcon]="true" dateFormat="yy/mm/dd"
                dataType="string"></p-calendar>
            <label for="calendar">Fecha de asignación</label>
        </span>
    </div>
</div>