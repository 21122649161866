<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('solicitud')">
        <br>
        <span class="p-float-label">
            <input id="fl-sol" type="text" pInputText [(ngModel)]="item.solicitud">
            <label for="fl-sol"><span *ngIf="operationMode == OP_MODE.ADDNEW" style="color: red;">* </span>Solicitud</label>
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('idTipoSolicitud')">
        <br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-tipoSol" [(ngModel)]="item.idTipoSolicitud" [options]="catTipoSol"
                optionLabel="tipoSolicitud" optionValue="idTipoSolicitud" [autoDisplayFirst]="false"
                [virtualScroll]="virtualScroll" itemSize="10" [filter]="true" filterBy="tipoSolicitud" autoWidth="false"
                (onChange)="selTipoSolicitud($event)"></p-dropdown>
            <label for="fl-tipoSol"><span *ngIf="operationMode == OP_MODE.ADDNEW" style="color: red;">* </span>Tipo de solicitud</label>
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('idEquipo') && showEquipo">
        <br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-equipo" [(ngModel)]="item.idEquipo" [options]="catEquiposEmpleado"
                optionLabel="nombre" optionValue="id" [autoDisplayFirst]="false" [virtualScroll]="virtualScroll"
                itemSize="10" [filter]="true" filterBy="nombre" autoWidth="false"></p-dropdown>
            <label for="fl-equipo"><span *ngIf="operationMode == OP_MODE.ADDNEW" style="color: red;">* </span>Equipo</label>
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12" *ngIf="allowFormFields('catEmpleados') && modoSoporte">
        <br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-equipo" [(ngModel)]="item.idEmpleado" [options]="catEmpleados"
                optionLabel="nombreCompleto" optionValue="id" [autoDisplayFirst]="false" [virtualScroll]="virtualScroll"
                itemSize="10" [filter]="true" filterBy="nombreCompleto" autoWidth="false"></p-dropdown>
            <label for="fl-equipo">Empleado</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12" *ngIf="operationMode == OP_MODE.ADDNEW">
        <br>
        <span style="color: red; font-size: 0.7rem;">* Campos requeridos</span>
    </div>
</div>