import { Component, Input, OnChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseView, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Caracteristica } from 'src/app/api/caracteristica';
import { EquipoCar, EquipoCarReg } from 'src/app/api/equipo';
import { CaracteristicaPsService } from 'src/app/procs/caracteristica/caracteristica-ps.service';
import { Equipo } from '../../../api/equipo';

@Component({
  selector: 'axks-caracteristica-equipo',
  templateUrl: './caracteristica-equipo.component.html',
  styleUrls: ['./caracteristica-equipo.component.scss']
})
export class CaracteristicaEquipoComponent extends BaseView implements OnInit {

  @Input() equipo: Equipo;

  @Input() caracteristicas: EquipoCar[];

  @Input() operationMode: OperationMode;

  @Input() idTipoEquipo: number;

  @Input() onChange: Caracteristica;

  prueba: EquipoCarReg;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    protected psCaract: CaracteristicaPsService) {

    super(screenModeService, deviceService, messageService, sessionProvier);

  }

  ngOnInit(): void {
    if (!this.caracteristicas) {
      this.caracteristicas = [{}] as EquipoCarReg[];
    }

    if (!this.equipo) {
      this.equipo = {
        caracteristicas: [{}]
      };
    }
  }

  insertar(items: EquipoCar) {
    this.equipo.caracteristicas.push({
      valor: items.valor,
      idCaracteristica: items.idCaracteristica
    });
  }

  quitar(item: EquipoCar) {
    this.equipo.caracteristicas.splice(this.equipo.caracteristicas.indexOf(item), 1);
  }
}
