import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { Message, OperationMode } from '@axks/components';
import { MessageService } from 'primeng/api';
import { Marca } from 'src/app/api/marca';
import { MarcaPsService } from 'src/app/procs/marca/marca-ps.service';
import { MessageCodes } from '@axks/components';

@Component({
  selector: 'axks-marca-cat',
  templateUrl: './marca-cat.component.html',
  styleUrls: ['./marca-cat.component.scss']
})
export class MarcaCatComponent implements OnInit, OnChanges {

  @Input() value?: Marca = {};
  @Input() idTipoEquipo?: number;
  @Input() operationMode: OperationMode;
  @Input() addNewEnable: boolean;

  @Output() onChange = new EventEmitter<number>();

  catalog: Marca[];
  virtualScroll: boolean = false;
  showForm: boolean = false;
  newItem: Marca;

  constructor(private arestps: MarcaPsService, private messages: MessageService) { 

  }

  ngOnInit(): void {
    if(!this.value){
      this.value = {} as Marca;
    }
    if (this.operationMode) {
      this.enableAddNewButton();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.populate();
    if(!this.operationMode){
      this.enableAddNewButton();
    }
  }

  populate() {
    let filter = {} as Marca;
    if (this.idTipoEquipo) {
      filter.tipoEquipo = { id: this.idTipoEquipo };
    }
    this.virtualScroll = false;
    this.arestps.find(filter).subscribe(
      (data) => {
        this.catalog = data;
        if (this.catalog.length > 10) {
          this.virtualScroll = true;
        }
      },
      (error) => {
        try {

          let message = error.error as Message;
          this.processMessage(message);
        } catch (e) {
          console.debug("Error")
          console.debug(error)
        }
      }
    );
  }

  enableAddNewButton() {
    switch (this.operationMode) {
      case OperationMode.ADDNEW:
        this.addNewEnable = true;
        break;
      case OperationMode.EDIT:
        this.addNewEnable = true;
        break;
      default:
        this.addNewEnable = false;
        break;
    }
  }

  processMessage(message: Message) {
    let messText: string;
    let sevMes = "info";
    switch (message.code) {
      case MessageCodes.NOT_FOUND_RESULTS.valueOf():
        messText = "No se localizaron marcas en está Área. Deberá registrarlos primero.";
        break;
      default:
        messText = "Error desconocido.";
        sevMes = "warn"
        console.debug(message);
        break;
    }

    this.messages.add({ severity: sevMes, summary: "Catálogo de marcas", detail: messText });
  }

  selectOption(event, value){
    this.onChange.emit(value.idMarca);
  }

  onAddNewClick(event) {
    this.operationMode = OperationMode.ADDNEW;
    this.showForm = true;
    this.newItem = {
      tipoEquipo: {}
     } as Marca;
  }

  onSaveClick(event) {
    this.arestps.register(this.newItem).subscribe(
      (data) => {
        this.populate();
        this.showForm = false;
        this.newItem = {
          tipoEquipo: {}
        };
      }, (error) => {
        try {
          let message = error.error as Message;
          this.processMessage(message);
        } catch (e) {
          console.debug("Error")
          console.debug(error)
        }
        this.showForm = false;
        this.newItem = {
          tipoEquipo: {}
        };
      });
  }

  onCancelClick(event) {
    this.showForm = false;
    this.newItem = {
      tipoEquipo: {}
    };
  }

}