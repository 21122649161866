import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, ViewChild } from '@angular/core';
import { BaseView, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Equipo, EquipoCarReg, TipoEquipo } from 'src/app/api/equipo';
import { EmpleadoNombre } from 'src/app/api/empleado';
import { EmpleadoPsService } from 'src/app/procs/empleado/empleado-ps.service';
import { TipoEquipoPsService } from 'src/app/procs/tipo-equipo/tipo-equipo-ps.service';
import { CaracteristicaEquipoComponent } from '../caracteristica-equipo/caracteristica-equipo.component';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { Estado } from '../../../api/estado';
import { EstadoPsService } from '../../../procs/estado/estado-ps.service';

@Component({
  selector: 'axks-equipo-form',
  templateUrl: './equipo-form.component.html',
  styleUrls: ['./equipo-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EquipoFormComponent extends BaseView implements OnInit {

  @Input() item: Equipo;
  @Input() operationMode: OperationMode;
  @Input() showCaract: boolean;
  @Input() profile: ProfileC;


  @Output() onHideCaract = new EventEmitter<boolean>();
  @Output() onBuscaDisponibles = new EventEmitter<boolean>();
  @ViewChild(CaracteristicaEquipoComponent) componentCaractEquipo: CaracteristicaEquipoComponent;

  yearRange: any;
  catTipoEquipo: TipoEquipo[];
  estadosEquipo: Estado[];
  empleados: EmpleadoNombre[];
  catDisponibilidad: string[];

  OP_MODE = OperationMode;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private clientEmp: EmpleadoPsService,
    private tipoEquipoPs: TipoEquipoPsService,
    private estadoRestc: EstadoPsService
  ) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {

    if (!this.item) {
      this.item = {
        modelo: {
          marca: {
            tipoEquipo: {}
          }
        },
        estado: {},
        caracteristicas: [{}]
      };
    }

    this.initDates();
    this.initEstadoEquipo();
    this.initCatEmpleados();
    this.initTiposEquipo();
    this.initCatDisponibilidad();
  }

  initDates() {
    let now = new Date();
    let year = now.getFullYear();

    this.yearRange = '2012:' + (year + 1);
  }

  initTiposEquipo() {
    this.tipoEquipoPs.all().subscribe(
      (data) => {
        this.catTipoEquipo = data as TipoEquipo[];
      },
      (error) => {
        this.errorMessage("Error. Tipo de equipo", "No se pudo obtener los tipos de equipo");
      }
    );
  }

  initCatDisponibilidad() {
    this.catDisponibilidad = ["Disponible", "Asignado"];
  }

  initEstadoEquipo() {
    this.estadoRestc.find({}).subscribe(
      (data) => {
        this.estadosEquipo = data;
      },
      (error) => {
        this.errorMessage("Error, Estado de equipo", "No se pudo consultar los estados de equipo");
      }
    );
  }

  marcaDisponibles(event) {
    this.onBuscaDisponibles.emit(event.checked);
  }

  ocultarCardCaract(event) {
    this.onHideCaract.emit(false);
  }

  guardarCaract() {
    this.showCaract = false;
  }

  insertar(event) {
    this.componentCaractEquipo.insertar({} as EquipoCarReg);
  }

  initCatEmpleados() {
    this.clientEmp.empleadosContratoActivo(this.profile.empresa).subscribe(
      (data) => {
        this.empleados = data;
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error. Empleados', detail: 'No se pudo obtener la lista de empleados.' });
      }
    );
  }

  tipoEquipoChange(event) {
    this.item.modelo.marca.tipoEquipo.id = event as number;
  }

  modeloChange(event) {
    this.item.modelo.idModelo = event as number;
  }

  marcaChange(event) {
    this.item.modelo.marca.idMarca = event as number;
  }

}

