<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('idTipoEquipo')">
        <axks-tipo-equipo-cat [value]="item.parte.tipoEquipo" (onChange)="onChangeTipoEquipo($event)">
        </axks-tipo-equipo-cat>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('parte') && item.parte.idTipoEquipo">
        <axks-parte-cat [value]="item.parte" [idTipoEquipo]="item.parte.idTipoEquipo" [addNewEnable]="true" [operationMode]="operationMode"></axks-parte-cat>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('reparacion')">
        <br>
        <span class="p-float-label">
            <input id="fl-rep" type="text" pInputText [(ngModel)]="item.reparacion" [style]="{'width':'80%'}">
            <label for="fl-rep">Reparación</label>
        </span>
    </div>
</div>