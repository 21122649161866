import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { EmpleadoNombre } from 'src/app/api/empleado';
import { Tecnico } from 'src/app/api/tecnico';
import { EmpleadoPsService } from 'src/app/procs/empleado/empleado-ps.service';
import { TecnicoPsService } from 'src/app/procs/tecnico/tecnico-ps.service';

@Component({
  selector: 'axks-tecnico-form',
  templateUrl: './tecnico-form.component.html',
  styleUrls: ['./tecnico-form.component.scss']
})
export class TecnicoFormComponent extends BaseView implements OnInit {

  @Input() item: Tecnico;
  empleados: EmpleadoNombre[];
  @Input() profile: ProfileC;

  constructor(screenModeService: OperationScreenModeService,
    deviceService: DeviceDetectorService,
    messageService: MessageService,
    sessionProvier: SessionClientProvider,
    private clientEmp: EmpleadoPsService) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }
  
  ngOnInit(): void {
    if (!this.item) {
      this.item = {};
    }

    this.initCatEmpleados();
  }

  initCatEmpleados() {
    this.clientEmp.empleadosContratoActivo(this.profile.empresa).subscribe(
      (data) => {
        this.empleados = data;
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo obtener la lista de empleados.' });
      }
    );
  }

}
