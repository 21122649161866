import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { Message, OperationMode } from '@axks/components';
import { MessageService } from 'primeng/api';
import { Modelo } from 'src/app/api/modelo';
import { ModeloPsService } from 'src/app/procs/modelo/modelo-ps.service';
import { MessageCodes } from '@axks/components';

@Component({
  selector: 'axks-modelo-cat',
  templateUrl: './modelo-cat.component.html',
  styleUrls: ['./modelo-cat.component.scss']
})
export class ModeloCatComponent implements OnInit, OnChanges {

  @Input() value?: Modelo;
  @Input() idMarca: number;
  @Input() addNewEnable: boolean;
  @Input() operationMode: OperationMode;

  @Output() onChange = new EventEmitter<number>();

  catalog: Modelo[];
  virtualScroll: boolean = false;
  showForm: boolean = false;
  newItem: Modelo;

  constructor(private modeloPs: ModeloPsService, private messages: MessageService) {
  }


  ngOnInit(): void {
    if(!this.value){
      this.value = {} as Modelo;
    }
    if (this.operationMode) {
      this.enableAddNewButton();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.populate();
    if (this.operationMode) {
      this.enableAddNewButton();
    }
  }

  enableAddNewButton() {
    switch (this.operationMode) {
      case OperationMode.ADDNEW:
        this.addNewEnable = true;
        break;
      case OperationMode.EDIT:
        this.addNewEnable = true;
        break;
      default:
        this.addNewEnable = false;
        break;
    }
  }

  populate() {
    let filter = {
      marca: {}
    } as Modelo;

    if (this.idMarca) {
      filter.marca.idMarca = this.idMarca;
    }

    this.virtualScroll = false;
    this.modeloPs.find(filter).subscribe(
      (data) => {
        this.catalog = data;
        if (this.catalog.length > 10) {
          this.virtualScroll = true;
        }
      },
      (error) => {
        try {

          let message = error.error as Message;
          console.debug("Message: ");
          console.debug(message)
          this.processMessage(message);
        } catch (e) {
          console.debug("Error")
          console.debug(error)
        }
      }
    );
  }

  processMessage(message: Message) {
    let messText: string;
    let sevMes = "info";
    switch (message.code) {
      case MessageCodes.NOT_FOUND_RESULTS.valueOf():
        messText = "No se localizaron modelos en está área. Deberá registrarlos primero.";
        break;
      default:
        messText = "Error desconocido.";
        sevMes = "warn"
        console.debug(message);
        break;
    }

    this.messages.add({ severity: sevMes, summary: "Catálogo de modelos", detail: messText });
  }

  selectOption(event, value) {
    this.onChange.emit(value.idModelo);
  }

  onAddNewClick(event) {
    this.operationMode = OperationMode.ADDNEW;
    this.showForm = true;
    this.newItem = {
      marca: {
        tipoEquipo: {}
      }
    } as Modelo;
  }

  onSaveClick(event) {
    this.modeloPs.register(this.newItem).subscribe(
      (data) => {
        this.populate();
        this.showForm = false;
        this.newItem = {
          marca: {
            tipoEquipo: {}
          }
        };
      }, (error) => {
        try {
          let message = error.error as Message;
          this.processMessage(message);
        } catch (e) {
          console.debug("Error")
          console.debug(error)
        }
        this.showForm = false;
        this.newItem = {
          marca: {
            tipoEquipo: {}
          }
        };
      });
  }

  onCancelClick(event) {
    this.showForm = false;
    this.newItem = {
      marca: {
        tipoEquipo: {}
      }
    };
  }

}