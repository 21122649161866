import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { EstadoRestcService } from 'src/app/client/estado/estado-restc.service';

@Injectable({
  providedIn: 'root'
})
export class EstadoPsService extends BaseProcess<EstadoRestcService>{

  constructor(httpClient: HttpClient, crest: EstadoRestcService) {
    super(httpClient, crest);
  }
}
