import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Message } from '@axks/components';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { Observable, pipe, Subject } from 'rxjs';
import { SeguimientoReg } from 'src/app/api/solicitud/solicitud';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators'


@Injectable({
    providedIn: 'root'
})
export class SolicitudRestcService extends ARestClient {

    private _refresh$ = new Subject<void>();

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    getBaseEndpoint(): string {
        let path = environment.msSolicitud;
        if (!path.endsWith("/")) {
            path += "/";
        }
        return path;
    }
    processBody(res: any) {
        return res || {};
    }

    getRestPaths(): RestBasicPaths {
        let paths = super.getRestPaths();
        paths.remove = "";
        paths.detail = "";
        paths["solIngresadaAct"] = "seguimiento/sol-ingresada-act";
        paths["solRecibidaAct"] = "seguimiento/sol-recibida-act";
        paths["solRecepEquipoAct"] = "seguimiento/prog-recep-equipo-act";
        paths["solEsperaEquipoAct"] = "seguimiento/espera-equipo-act";
        paths["solEquipoRecibidoAct"] = "seguimiento/equipo-recibido-act";
        paths["solEnRevisionAct"] = "seguimiento/en-revision-act";
        paths["solDiagnosticoAct"] = "seguimiento/diagnostico-act";
        paths["solAtendidaAct"] = "seguimiento/atendida-act";
        paths["solEquipoEntregadoAct"] = "seguimiento/equipo-entregado-act";
        paths["solGeneraCorreoAct"] = "seguimiento/correo-generado-act";
        paths["solLicenciaInstaladaAct"] = "seguimiento/licencia-instalada-act";
        paths["solSOInstaladoAct"] = "seguimiento/s-o-instalado-act";
        paths["solProgramaSesionSOAct"] = "seguimiento/programa-sesion-s-o-act";
        paths["solFinalizadaAct"] = "seguimiento/finalizada";
        //paths["solAtedidaAct"] = "seguimiento/session-atendida";

        return paths;
    }

    /* solAtedidaAct(item: SeguimientoReg): Observable<Message> {
        let path = this.getBaseEndpoint() + this.getRestPaths()["solAtedidaAct"];

        if (!path.endsWith("/")) {
            path += "/"
        }
        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers', "*");

        return this.httpClient.post(path, item, { headers }) as Observable<Message>;
    }
 */

    solFinalizadaAct(item: SeguimientoReg): Observable<Message> {
        let path = this.getBaseEndpoint() + this.getRestPaths()["solFinalizadaAct"];

        if (!path.endsWith("/")) {
            path += "/"
        }
        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers', "*");

        return this.httpClient.post(path, item, { headers }) as Observable<Message>;
    }

    solProgramaSesionSOAct(item: SeguimientoReg): Observable<Message> {
        let path = this.getBaseEndpoint() + this.getRestPaths()["solProgramaSesionSOAct"];

        if (!path.endsWith("/")) {
            path += "/"
        }
        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers', "*");

        return this.httpClient.post(path, item, { headers }) as Observable<Message>;
    }

    solSOInstaladoAct(item: SeguimientoReg): Observable<Message> {
        let path = this.getBaseEndpoint() + this.getRestPaths()["solSOInstaladoAct"];

        if (!path.endsWith("/")) {
            path += "/"
        }
        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers', "*");

        return this.httpClient.post(path, item, { headers }) as Observable<Message>;
    }


    solLicenciaInstaladaAct(item: SeguimientoReg): Observable<Message> {
        let path = this.getBaseEndpoint() + this.getRestPaths()["solLicenciaInstaladaAct"];

        if (!path.endsWith("/")) {
            path += "/"
        }

        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers', "*");

        return this.httpClient.post(path, item, { headers }) as Observable<Message>;
    }

    solGeneraCorreoAct(item: SeguimientoReg): Observable<Message> {
        let path = this.getBaseEndpoint() + this.getRestPaths()["solGeneraCorreoAct"];

        if (!path.endsWith("/")) {
            path += "/"
        }
        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers', "*");

        return this.httpClient.post(path, item, { headers }) as Observable<Message>;
    }

    solicitudIngresadaAct(item: SeguimientoReg): Observable<Message> {
        let path = this.getBaseEndpoint() + this.getRestPaths()["solIngresadaAct"];

        if (!path.endsWith("/")) {
            path += "/"
        }
        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers', "*");

        return this.httpClient.post(path, item, { headers }) as Observable<Message>;
    }

    solicitudRecibidaAct(item: SeguimientoReg): Observable<Message> {
        let path = this.getBaseEndpoint() + this.getRestPaths()["solRecibidaAct"];

        if (!path.endsWith("/")) {
            path += "/"
        }
        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers', "*");

        return this.httpClient.post(path, item, { headers }) as Observable<Message>;
    }

    solicitaEquipoParaRevisionAct(item: SeguimientoReg): Observable<Message> {
        let path = this.getBaseEndpoint() + this.getRestPaths()["solRecepEquipoAct"];

        if (!path.endsWith("/")) {
            path += "/"
        }
        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers', "*");

        return this.httpClient.post(path, item, { headers }) as Observable<Message>;
    }

    solicitudEsperaEquipoAct(item: SeguimientoReg): Observable<Message> {
        let path = this.getBaseEndpoint() + this.getRestPaths()["solEsperaEquipoAct"];

        if (!path.endsWith("/")) {
            path += "/"
        }
        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers', "*");

        return this.httpClient.post(path, item, { headers }) as Observable<Message>;
    }

    solicitudEquipoRecibidoAct(item: SeguimientoReg): Observable<Message> {
        let path = this.getBaseEndpoint() + this.getRestPaths()["solEquipoRecibidoAct"];

        if (!path.endsWith("/")) {
            path += "/"
        }
        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers', "*");

        return this.httpClient.post(path, item, { headers }) as Observable<Message>;
    }


    solicitudEnRevisionAct(item: SeguimientoReg): Observable<Message> {
        let path = this.getBaseEndpoint() + this.getRestPaths()["solEnRevisionAct"];

        if (!path.endsWith("/")) {
            path += "/"
        }
        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers', "*");

        return this.httpClient.post(path, item, { headers }) as Observable<Message>;
    }

    solicitudDiagnosticoAct(item: SeguimientoReg): Observable<Message> {
        let path = this.getBaseEndpoint() + this.getRestPaths()["solDiagnosticoAct"];

        if (!path.endsWith("/")) {
            path += "/"
        }
        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers', "*");

        return this.httpClient.post(path, item, { headers }) as Observable<Message>;
    }

    solicitudAtendidaAct(item: SeguimientoReg): Observable<Message> {
        let path = this.getBaseEndpoint() + this.getRestPaths()["solAtendidaAct"];

        if (!path.endsWith("/")) {
            path += "/"
        }

        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers', "*");

        return this.httpClient.post(path, item, { headers }) as Observable<Message>;
    }

    solicitudEquipoEntregadoAct(item: SeguimientoReg): Observable<Message> {
        let path = this.getBaseEndpoint() + this.getRestPaths()["solEquipoEntregadoAct"];

        if (!path.endsWith("/")) {
            path += "/"
        }
        
        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers', "*");

        return this.httpClient.post(path, item, { headers }) as Observable<Message>;
    }

    servicioDetalle(id: number): Observable<any> {
        let path = environment.msSolicitud + "/" + id;

        const headers = new HttpHeaders()
            .set('Access-Control-Allow-Origin', "*")
            .set('Access-Control-Allow-Headers', "*");
        return this.httpClient.get(path, { headers });
    }

}
