import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Reparacion, ReparacionReg } from 'src/app/api/solicitud/reparacion';
import { ReparacionPsService } from 'src/app/procs/solicitud/reparacion-ps.service';

@Component({
  selector: 'axks-reparacion',
  templateUrl: './reparacion.component.html',
  styleUrls: ['./reparacion.component.scss']
})
export class ReparacionComponent extends BaseMainComponent<ReparacionPsService> implements OnInit {


  constructor(deviceService: DeviceDetectorService,
    messageService: MessageService,
    screenModeService: OperationScreenModeService,
    sessionProvier: SessionClientProvider,
    private reparaPs: ReparacionPsService) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      registerItem: {parte: {tipoEquipo: {}}} as ReparacionReg,
      item: {parte: {tipoEquipo: {}}} as Reparacion,
      minimumItem: {parte: {tipoEquipo: {}}} as Reparacion
    } as ComponentItem;

    return empty;
  }
  getService(): ReparacionPsService {
    return this.reparaPs;
  }
  getColListDefinition(): ColumnDefinition[] {
    return [
      {header:"Clave", field:"id", inAll:true},
      {header:"Reparación", field:"reparacion", inAll:true},
      {header:"Parte", field:"parte.parte", inAll:true}
    ] as ColumnDefinition[];
  }
  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }
  getComponentTitle(): string {
    return "Reparaciones";
  }

}
