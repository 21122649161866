import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseView, Message, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { EstatusSolicitud, TipoSolicitud } from 'src/app/api/enums/solicitud.enum';
import { Seguimiento, SeguimientoReg, Solicitud } from 'src/app/api/solicitud/solicitud';
import { Tecnico } from 'src/app/api/tecnico';
import { SeguimientoService } from 'src/app/procs/solicitud/observable/seguimiento.service';
import { SolicitudPsService } from 'src/app/procs/solicitud/solicitud-ps.service';
import { LabelPsService } from 'src/app/procs/content/label-ps.service';
import { Diagnostico } from 'src/app/api/solicitud/diagnostico';
import { Falla } from 'src/app/api/solicitud/falla';
import { Atencion } from 'src/app/api/solicitud/atencion';
import { Reparacion } from 'src/app/api/solicitud/reparacion';
import { SolicitudRestcService } from 'src/app/client/solicitud/solicitud-restc.service';

@Component({
  selector: 'axks-accion-tec-seguimiento',
  templateUrl: './accion-tec-seguimiento.component.html',
  styleUrls: ['./accion-tec-seguimiento.component.scss']
})
export class AccionTecSeguimientoComponent extends BaseView implements OnInit {

  ESTATUS = EstatusSolicitud;
  tipoSolicitud = TipoSolicitud;

  @Input() seguimiento: Seguimiento;
  @Input() idTipoSolicitud: number;

  @Output() onFinAccion = new EventEmitter();

  fallaDiag: Diagnostico;
  diagnostico: Diagnostico[];

  repAtencion: Atencion;
  actividadesSol: Atencion[];

  comentario?: string;

  tecnico: Tecnico;
  labels: any;

  catFallas: Falla[];

  showGuardarAtencion: boolean = false;
  showGuardarDiagnostico: boolean = false;
  isLoading: boolean = false;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private solPs: SolicitudPsService,
    private segObs: SeguimientoService,
    private labelPs: LabelPsService,
    private objetoFaltante: SolicitudRestcService
  ) {
    super(screenModeService, deviceService, messageService, sessionProvier);

    this.labelPs.labels.subscribe((data) => { this.labels = data });
  }

  ngOnInit(): void {
    this.tecnico = this.getProfile()["tecnico"];

    this.initDiagnostico();
    this.initAtencionSolicitud();
    this.datos();
    this.actividadesSol = [];
    if (this.seguimiento.diagnostico) {
      this.initCatFallasSolicitud();
    }

    this.isLoading = false;
  }

  datos() {
    this.objetoFaltante.servicioDetalle(this.seguimiento.idSolicitud).subscribe(
      (data) => {
        this.seguimiento.idTipoSolicitud = data.idTipoSolicitud;
      })
  }

  initCatFallasSolicitud() {
    this.catFallas = [];
    for (let repItem of this.seguimiento.diagnostico) {
      this.catFallas.push(repItem.falla);
    }
  }

  initDiagnostico() {
    // inicializa cuando el estatus es en revisión
    if (this.seguimiento.estatus.idEstatus == EstatusSolicitud.EN_REVISION) {
      this.fallaDiag = { falla: {} as Falla } as Diagnostico;
      this.fallaDiag.idSolicitud = this.seguimiento.idSolicitud;

      this.diagnostico = [];
    }
  }

  initAtencionSolicitud() {
    // Inicia si el seguimiento esta con un estatus de Diagnostico
    if (this.seguimiento.estatus.idEstatus == EstatusSolicitud.DIAGNOSTICO) {
      this.repAtencion = {
        solicitud: {} as Solicitud,
        reparacion: {} as Reparacion,
        falla: {} as Falla
      } as Atencion;

      this.actividadesSol = [];
    }
  }

  onSolicitudRecibidaAct(event) {

    this.isLoading = true;

    let seg = {} as SeguimientoReg;
    seg.idSegSolicitud = this.seguimiento.idSegSolicitud;
    seg.idSolicitud = this.seguimiento.idSolicitud;
    seg.comentario = this.comentario;
    seg.idTecnico = this.tecnico.id;
    seg.idTipoSolicitud = this.seguimiento.idTipoSolicitud;

    this.solPs.solicitudRecibidaAct(seg).subscribe(
      (data) => {
        let mess = data as Message;
        this.segObs.recargaHistoria.next(true);
        this.infoMessage("Seguimiento", mess.details);
        this.isLoading = false;
        this.onFinAccion.emit(true);
      },
      (error) => {
        this.isLoading = false;
        this.errorMessage("Error", "No se pudo avanzar la solicitud");
      }
    );
  }

  onEsperaEquipoAct(event) {

    this.isLoading = true;

    let seg = {} as SeguimientoReg;
    seg.idSegSolicitud = this.seguimiento.idSegSolicitud;
    seg.idSolicitud = this.seguimiento.idSolicitud;
    seg.comentario = this.comentario;
    seg.idTecnico = this.tecnico.id;
    seg.idTipoSolicitud = this.seguimiento.idTipoSolicitud;

    this.solPs.solEsperaEquipoAct(seg).subscribe(
      (data) => {
        let mess = data as Message;
        this.segObs.recargaHistoria.next(true);
        this.infoMessage("Seguimiento", mess.details);
        this.isLoading = false;
        this.onFinAccion.emit(true);
      },
      (error) => {
        this.isLoading = false;
        this.errorMessage("Error", "No se pudo avanzar la solicitud");
      }
    );
  }

  onEquipoRecibidoAct(event) {

    this.isLoading = true;

    let seg = {} as SeguimientoReg;
    seg.idSegSolicitud = this.seguimiento.idSegSolicitud;
    seg.idSolicitud = this.seguimiento.idSolicitud;
    seg.comentario = this.comentario;
    seg.idTecnico = this.tecnico.id;
    seg.idTipoSolicitud = this.seguimiento.idTipoSolicitud;

    this.solPs.solEquipoRecibidoAct(seg).subscribe(
      (data) => {
        let mess = data as Message;
        this.segObs.recargaHistoria.next(true);
        this.infoMessage("Seguimiento", mess.details);
        this.isLoading = false;
        this.onFinAccion.emit(true);
      },
      (error) => {
        this.isLoading = false;
        this.errorMessage("Error", "No se pudo avanzar la solicitud");
      }
    );
  }

  onEnRevisionAct(event) {

    this.isLoading = true;

    let seg = {} as SeguimientoReg;
    seg.idSegSolicitud = this.seguimiento.idSegSolicitud;
    seg.idSolicitud = this.seguimiento.idSolicitud;
    seg.comentario = this.comentario;
    seg.idTecnico = this.tecnico.id;
    seg.diagnostico = this.diagnostico;
    seg.idTipoSolicitud = this.seguimiento.idTipoSolicitud;


    this.solPs.solEquipoEnRevisionAct(seg).subscribe(
      (data) => {
        let mess = data as Message;
        this.segObs.recargaHistoria.next(true);
        this.infoMessage("Seguimiento", mess.details);
        this.isLoading = false;
        this.onFinAccion.emit(true);
      },
      (error) => {
        this.isLoading = false;
        this.errorMessage("Error", "No se pudo avanzar la solicitud");
      }
    );
  }

  onEnDiagnosticoAct(event) {

    this.isLoading = true;

    let seg = {} as SeguimientoReg;
    seg.idSegSolicitud = this.seguimiento.idSegSolicitud;
    seg.idSolicitud = this.seguimiento.idSolicitud;
    seg.comentario = this.comentario;
    seg.idTecnico = this.tecnico.id;
    seg.atenciones = this.actividadesSol;


    this.solPs.solEquipoDiagnosticoAct(seg).subscribe(
      (data) => {
        let mess = data as Message;
        this.segObs.recargaHistoria.next(true);
        this.infoMessage("Seguimiento", mess.details);
        this.isLoading = false;
        this.onFinAccion.emit(true);
      },
      (error) => {
        this.isLoading = false;
        this.errorMessage("Error", "No se pudo avanzar la solicitud");
      }
    );
  }

  onFallaSel(event) {
    this.fallaDiag.falla = event;
  }

  onAgregaAlDiagnostico(event) {

    if (this.fallaDiag && this.fallaDiag.falla && this.fallaDiag.falla.id && this.fallaDiag.comentario && this.fallaDiag.comentario.trim() != "") {
      this.fallaDiag.idFalla = this.fallaDiag.falla.id;
      this.diagnostico.push(this.fallaDiag);
      this.fallaDiag = { falla: {} as Falla } as Diagnostico;
      this.fallaDiag.idSolicitud = this.seguimiento.idSolicitud;

      this.showGuardarDiagnostico = true;
    }
    else {
      this.messageService.add({
        summary: "Error",
        detail: "Favor de llenar el diagnostico correctamente.",
        severity: "error",
        key: "msgErroDiag"
      });
    }
  }

  onCancelarFallaDiagnostico(event) {
    this.fallaDiag = { falla: {} as Falla } as Diagnostico;
    this.fallaDiag.idSolicitud = this.seguimiento.idSolicitud;
  }

  onQuitarFallaDelDiagnostico(event, idFalla, item: Diagnostico) {
    this.diagnostico.splice(this.diagnostico.indexOf(item), 1);
    //this.diagnostico = this.diagnostico.filter(ditem => ditem.idFalla !== idFalla);
    if (this.diagnostico.length == 0) {
      this.showGuardarDiagnostico = false;
    }
  }

  onReparacionSel(event) {
    this.repAtencion.reparacion = event;
  }

  selectItemOnFallaAtenc(event) {
    this.repAtencion.falla = event.value;
    this.repAtencion.idFalla = event.value.id;
  }

  onAgregaReparacionEnAtenciones(event) {

    if (this.repAtencion && this.repAtencion.reparacion && this.repAtencion.reparacion.id &&
      this.repAtencion.observacion && this.repAtencion.observacion.trim() != "" && this.repAtencion.falla &&
      this.repAtencion.falla.id && this.repAtencion.falla.falla && this.repAtencion.falla.falla.trim() != "") {

      this.repAtencion.idReparacion = this.repAtencion.reparacion.id;
      this.repAtencion.idSolicitud = this.seguimiento.idSolicitud;
      this.repAtencion.idFalla = this.repAtencion.falla.id;

      this.actividadesSol.push(this.repAtencion);
      this.repAtencion = {
        solicitud: {} as Solicitud,
        reparacion: {} as Reparacion,
        falla: {} as Falla
      } as Atencion;
      this.repAtencion.falla = {};
      this.repAtencion.idSolicitud = this.seguimiento.idSolicitud;
      this.showGuardarAtencion = true;
    }
    else {
      this.messageService.add({
        summary: "Error",
        detail: "Favor de llenar la atencion con los campos requeridos.",
        severity: "error",
        key: "msgErrorAtenc"
      });
    }
  }

  onCancelarRepEnAtenciones(event) {
    this.repAtencion = this.repAtencion = {
      solicitud: {} as Solicitud,
      reparacion: {} as Reparacion,
      falla: {} as Falla
    } as Atencion;
    this.repAtencion.idSolicitud = this.seguimiento.idSolicitud;

  }

  onQuitarReparacionDeAtenciones(event, idReparacion, item: Atencion) {
    this.actividadesSol.splice(this.actividadesSol.indexOf(item), 1);
    //this.actividadesSol = this.actividadesSol.filter(aitem => aitem.reparacion.id !== idReparacion);
    if (this.actividadesSol.length == 0) {
      this.showGuardarAtencion = false;
    }
  }

  onSolAtendidaAct() {

    this.isLoading = true;

    let seg = {} as SeguimientoReg;
    seg.idSegSolicitud = this.seguimiento.idSegSolicitud;
    seg.idSolicitud = this.seguimiento.idSolicitud;
    seg.idTipoSolicitud = this.seguimiento.idTipoSolicitud;
    seg.finSolicitud = false;
    seg.idTecnico = this.tecnico.id;

    this.solPs.solAtendidaAct(seg).subscribe(
      (data) => {
        let mess = data as Message;
        this.segObs.recargaHistoria.next(true);
        this.infoMessage("Seguimiento", mess.details);
        this.isLoading = false;
        this.onFinAccion.emit(true);
      },
      (error) => {
        this.isLoading = false;
        this.errorMessage("Error", "No se pudo avanzar la solicitud");
      }
    );
  }

}
