import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageCodes, OperationMode } from '@axks/components';
import { MessageService } from 'primeng/api';
import { Caracteristica } from 'src/app/api/caracteristica';
import { EquipoCarReg } from 'src/app/api/equipo';
import { CaracteristicaPsService } from 'src/app/procs/caracteristica/caracteristica-ps.service';

@Component({
  selector: 'axks-caracteristica-cat',
  templateUrl: './caracteristica-cat.component.html',
  styleUrls: ['./caracteristica-cat.component.scss']
})
export class CaracteristicaCatComponent implements OnInit, OnDestroy, OnChanges {

  catalog: Caracteristica[];
  virtualScroll: boolean = false;

  showForm: boolean = false;
  newItem: Caracteristica;

  @Input() item?: Caracteristica;
  /**
   * Habilita la funcionalidad para agregar
   */
  @Input() addNewEnable: boolean = false;

  /** 
   * Indica si hay que tomar en cuenta el modo de operación 
   * de la pantalla.
   */
  @Input() operationMode: OperationMode;


  @Input() idTipoEquipo: number;
  /* @Output() nuevaCaracteristica = new EventEmitter<EquipoCarReg>();
 */
  @Input() caractPadre?: EquipoCarReg;

  constructor(private psCaract: CaracteristicaPsService,
    private messages: MessageService,
    private route: ActivatedRoute) {

  }

  ngOnDestroy(): void {

  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {} as Caracteristica;
    }
    if (this.operationMode) {
      this.enableAddNewButton();
    }

    // Temporal mientras se desarrolla
    /* this.route.data.subscribe((data) => {
      this.addNewEnable = data.addNewEnable;
      this.idTipoEquipo = data.idTipoEquipo;
      this.populate();
    }) */

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.populate();
  }

  enableAddNewButton() {
    switch (this.operationMode) {
      case OperationMode.ADDNEW:
        this.addNewEnable = true;
        break;
      case OperationMode.EDIT:
        this.addNewEnable = true;
        break;
      default:
        this.addNewEnable = false;
        break;
    }
  }

  populate() {
    let filter = {} as Caracteristica;
    filter.idTipoEquipo = this.idTipoEquipo;


    this.virtualScroll = false;
    this.psCaract.find(filter).subscribe(
      (data) => {
        this.catalog = data as Caracteristica[];
        if (this.catalog.length > 10) {
          this.virtualScroll = true;
        }
      },
      (error) => {
        try {

          let message = error.error as Message;
          this.processMessage(message);
        } catch (e) {
          console.debug("Error")
          console.debug(error)
        }
      }
    );
  }

  processMessage(message: Message) {
    let messText: string;
    let sevMes = "info";
    switch (message.code) {
      case MessageCodes.NOT_FOUND_RESULTS.valueOf():
        messText = "No se localizaron las caracteristicas de los equipos. Deberá registrarlos primero.";
        break;
      default:
        messText = "Error desconocido.";
        sevMes = "warn"
        console.debug(message);
        break;
    }

    this.messages.add({ severity: sevMes, summary: "Catálogo de caracteristicas de equipo", detail: messText });
  }

  onAddNewClick(event) {
    this.operationMode = OperationMode.ADDNEW;
    this.showForm = true;
    this.newItem = { idTipoEquipo: this.idTipoEquipo } as Caracteristica;
  }

  onSaveClick(event) {
    this.psCaract.register(this.newItem).subscribe(
      (data) => {
        this.populate();
        this.showForm = false;
        this.newItem = undefined;
      }, (error) => {
        try {
          let message = error.error as Message;
          this.processMessage(message);
        } catch (e) {
          console.debug("Error")
          console.debug(error)
        }
        this.showForm = false;
        this.newItem = undefined;
      });
  }

  onCancelClick(event) {
    this.showForm = false;
    this.newItem = undefined;
  }

  modificarItemPadre(item: Caracteristica) {
    this.caractPadre.idCaracteristica = item;
  }

}
