import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Parte } from 'src/app/api/parte';
import { Falla } from 'src/app/api/solicitud/falla';

@Component({
  selector: 'axks-falla-form',
  templateUrl: './falla-form.component.html',
  styleUrls: ['./falla-form.component.scss']
})
export class FallaFormComponent extends BaseView implements OnInit {

  @Input() item: Falla;

  idTipoEquipo: number;

  constructor(screenModeService: OperationScreenModeService, 
    deviceService: DeviceDetectorService, 
    messageService: MessageService, 
    sessionProvier: SessionClientProvider) {
    super(screenModeService, deviceService, messageService, sessionProvier);
   }

  ngOnInit(): void {
    if(!this.item){
      this.item = {
        parte: {} as Parte
      } as Falla;
    }

    try{
      this.item.parte.idTipoEquipo = this.item.parte.tipoEquipo.id;
    }catch(e){}
  }
  onChangeTipoEquipo(event){
    this.item.parte.idTipoEquipo = event;
  }

}
