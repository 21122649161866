import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { ReparacionRestcService } from 'src/app/client/solicitud/reparacion-restc.service';

@Injectable({
  providedIn: 'root'
})
export class ReparacionPsService extends BaseProcess< ReparacionRestcService > {

  constructor(httpClient: HttpClient, crest: ReparacionRestcService) { 
    super(httpClient, crest);
  }
}
