<!-- {{item | json}} -->

<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('parte')">
        <br>
        <span class="p-float-label">
            <input id="fl-parte" type="text" pInputText [(ngModel)]="item.parte">
            <label for="fl-parte">Parte</label>
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('idTipoEquipo')">
        <axks-tipo-equipo-cat [value]="item.tipoEquipo" (onChange)="onChangeTipoEquipo($event)"></axks-tipo-equipo-cat>
    </div>
    
</div>
