import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess, Message } from '@axks/components';
import { Observable } from 'rxjs';
import { SeguimientoReg } from 'src/app/api/solicitud/solicitud';
import { SolicitudRestcService } from 'src/app/client/solicitud/solicitud-restc.service';

@Injectable({
  providedIn: 'root'
})
export class SolicitudPsService extends BaseProcess<SolicitudRestcService>{

  client: SolicitudRestcService;
  constructor(httpClient: HttpClient, restc: SolicitudRestcService) {
    super(httpClient, restc);
    this.client = restc;
  }

  solicitudIngresadaAct(item: SeguimientoReg): Observable<Message> {
    return this.client.solicitudIngresadaAct(item);
  }

  solicitudRecibidaAct(item: SeguimientoReg): Observable<Message> {
    return this.client.solicitudRecibidaAct(item);
  }

  solEquipoParaRevisionAct(item: SeguimientoReg): Observable<Message> {
    return this.client.solicitaEquipoParaRevisionAct(item);
  }

  solEsperaEquipoAct(item: SeguimientoReg): Observable<Message> {
    return this.client.solicitudEsperaEquipoAct(item);
  }

  solEquipoRecibidoAct(item: SeguimientoReg): Observable<Message> {
    return this.client.solicitudEquipoRecibidoAct(item);
  }

  solEquipoEnRevisionAct(item: SeguimientoReg): Observable<Message> {
    return this.client.solicitudEnRevisionAct(item);
  }

  solSOInstaladoAct(item: SeguimientoReg): Observable<Message> {
    return this.client.solSOInstaladoAct(item)
  }

  solLicenciaInstaladaAct(item: SeguimientoReg): Observable<Message> {
    return this.client.solLicenciaInstaladaAct(item);
  }

  solGeneraCorreoAct(item: SeguimientoReg): Observable<Message> {
    return this.client.solGeneraCorreoAct(item);
  }
  
  solEquipoDiagnosticoAct(item: SeguimientoReg): Observable<Message> {
    return this.client.solicitudDiagnosticoAct(item);
  }

  solProgramaSesionSOAct(item: SeguimientoReg): Observable<Message> {
    return this.client.solProgramaSesionSOAct(item);
  }

  solFinalizadaAct(item: SeguimientoReg): Observable<Message> {
    return this.client.solFinalizadaAct(item);
  }

  solAtendidaAct(item: SeguimientoReg): Observable<Message> {
    return this.client.solicitudAtendidaAct(item);
  }

  solEquipoEntregadoAct(item: SeguimientoReg): Observable<Message> {
    return this.client.solicitudEquipoEntregadoAct(item);
  }

  /* solAtedidaAct(item: SeguimientoReg): Observable<Message> {
    return this.client.solAtedidaAct(item);
  } */

}
