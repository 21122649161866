import { Injectable } from '@angular/core';

import { ARestClient, RestBasicPaths } from '@axks/net';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Equipo, EquipoMin, EquipoReg, FiltroEquipo } from 'src/app/api/equipo';

/**
 * Rest client to communicate with equipo micro service.
 * 
 * @author Juan Godínez.
 */
@Injectable({
  providedIn: 'root'
})
export class EquipoRestcService extends ARestClient {


  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    return environment.msEquipo + "/";
  }
  processBody(res: any) {
    let body = res;
    return body || {};
  }

  getRestPaths(): RestBasicPaths {
    let paths = super.getRestPaths();
    paths.remove = "remove";
    paths.detail = "";
    paths["asigEmp"] = "empleado";
    paths["disponibles"] = "disponibles";
    
    return paths;
  }

  findAsignadosEmpleado(idEmpleado: number): Observable<EquipoMin[]> {
    let path = this.getBaseEndpoint() + this.getRestPaths()["asigEmp"];

    if (!path.endsWith("/")) {
      path += "/"
    }
    path += idEmpleado;
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.get(path, { headers }) as Observable<EquipoMin[]>;
  }

  getDisponibles(): Observable<Equipo[]>{
    let path = this.getBaseEndpoint() + this.getRestPaths()["disponibles"];

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.get(path, { headers }) as Observable<Equipo[]>;
  }

}