<p-dialog header="Asignar equipo" [(visible)]="display" [modal]=true [style]="dialogSize()"
    (onHide)="onHideDialog($event)">
    <!-- {{item | json}} -->
    <div class="p-grid" [style]="gridSize()">
        <div class="p-sm-12 p-md-6 p-lg-6 p-xl-6 p-col-12">
            <br>
            <span class="p-float-label">
                <p-dropdown inputId="fl-emp" [(ngModel)]="item.idEmpleado" [options]="empleados"
                    optionLabel="nombreCompleto" optionValue="id" [autoDisplayFirst]="false"
                    [virtualScroll]="virtualScroll" itemSize="10" [filter]="true" filterBy="nombreCompleto"
                    autoWidth="false" [style]="{'width':'80%'}" appendTo="body" [baseZIndex]="20000"></p-dropdown>
                <label for="fl-emp">Empleado:</label>
            </span>
        </div>
        <div class="p-sm-12 p-md-6 p-lg-6 p-xl-6 p-col-12">
            <br>
            <span class="p-float-label">
                <p-calendar id="fl-fecas" [(ngModel)]="item.fecAsignacion" dateFormat="yy-mm-dd" [yearNavigator]="true"
                    [yearRange]="yearRange" [monthNavigator]="true" showIcon="true" [style]="{'width':'80%'}"
                    appendTo="body"></p-calendar>
                <label for="fl-fecas">Fecha de asignación</label>
            </span>
        </div>
        <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12" style="padding: unset;">
            <h4 class="subtitulo-4">Detalle del equipo que se asigna</h4>
            <span class="det-field-lbl">Clave</span>
            <span class="det-field-val">{{equipo.id}}</span>
            <span class="det-field-lbl">Número de serie</span>
            <span class="det-field-val">{{equipo.numSerie}}</span>
            <span class="det-field-lbl">Tipo</span>
            <span class="det-field-val">{{equipo.modelo.marca.tipoEquipo.tipoEquipo}}</span>
            <span class="det-field-lbl">Marca</span>
            <span class="det-field-val">{{equipo.modelo.marca.marca}}</span>
            <span class="det-field-lbl">Modelo</span>
            <span class="det-field-val">{{equipo.modelo.modelo}}</span>
        </div>

    </div>
    <ng-template pTemplate="footer">
        <p-button [loading]="isLoading" (onClick)="onAsignar($event)">
            <fa-icon [icon]="iconFaFileImport"></fa-icon>
            <span class="p-ml-2">Asignar equipo</span>
        </p-button>
        <p-button label="Cancelar" icon="fa fa-ban" [loading]="isLoading" styleClass="p-button-danger"
            (onClick)="onCancelar($event)"></p-button>
    </ng-template>
</p-dialog>