import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'axks-graf-desp-mensual-dash',
  templateUrl: './graf-desp-mensual-dash.component.html',
  styleUrls: ['./graf-desp-mensual-dash.component.scss']
})
export class GrafDespMensualDashComponent implements OnInit {

  data: any;
  constructor() { }

  ngOnInit(): void {
    this.data = {
      labels:['No Atendidas','Atendidas'],
      datasets: [
        {
          data: [20,10],
          backgroundColor: [
            "#ff6384",
            "#36a2eb"
          ]
        }
      ]
    }
  }

}
