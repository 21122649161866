<h4 class="subtitulo-4">Historia de asignaciones de equipo</h4>
<p-scrollPanel [style]="{width: '100%', height: '54vh'}">

    <p-timeline [value]="historico" layout="vertical" align="alternate">
        <ng-template pTemplate="content" let-event>
            <p-card header="">
                <ng-template pTemplate="header" style="padding: 0.5rem;">
                    <small class="p-text-primary">{{event.fecAsignacion | date: "dd-MM-yyyy"}} - {{event.fecRecibe |
                        date: "dd-MM-yyyy"}}</small>
                </ng-template>
                <small class="p-text-primary">
                    {{event.empleado}}
                </small><br>
                <small class="p-text-primary" *ngIf="event.notasRecepcion">
                    Notas: {{event.notasRecepcion}}
                </small>
            </p-card>
        </ng-template>
    </p-timeline>

</p-scrollPanel>