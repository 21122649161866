<br>
<span class="p-float-label " style="float: left; width: 60%;">
    <!-- [editable]="true" (keyup.enter)="onEnter($event)" -->
    <p-dropdown inputId="fl-falla" [(ngModel)]="value.id" [options]="catalog" optionLabel="falla" optionValue="id"
        [autoDisplayFirst]="false" [virtualScroll]="virtualScroll" itemSize="10" [filter]="true" filterBy="falla,parte.parte"
        (onChange)="selectOption($event,value)" autoWidth="false" [style]="{'width':'100%'}">
        <ng-template pTemplate="item" let-citem>
            {{citem.parte.parte}} - {{citem.falla}}
        </ng-template>
    </p-dropdown>
    <label for="fl-falla">Falla</label>
</span>
<p-button icon="pi pi-plus" iconPos="left" *ngIf="addNewEnable && !showForm" (click)="onAddNewClick($event)">
</p-button>

<p-panel class="cat-form-popup" *ngIf="showForm" header="Nuevo Falla">
    <axks-falla-form [operationMode]="operationMode" [formFields]="[]" [item]="newItem"></axks-falla-form>
    <p-button label="Guardar" icon="fa fa-disk" iconPos="left" (click)="onSaveClick($event)"></p-button>
    &nbsp;
    <p-button label="Cancelar" icon="fa fa-close" iconPos="left" (click)="onCancelClick($event)"></p-button>
</p-panel>

<p-toast></p-toast>