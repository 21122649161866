<br>
<span class="p-float-label " style="float: left; width: 80%;">
    <!-- [editable]="true" (keyup.enter)="onEnter($event)" -->
    <p-dropdown inputId="fl-repa" [(ngModel)]="value.id" [options]="catalog" optionLabel="reparacion" optionValue="id"
        [autoDisplayFirst]="false" [virtualScroll]="virtualScroll" itemSize="10" [filter]="true"
        filterBy="reparacion,parte.parte" (onChange)="selectOption($event,value)" autoWidth="false"
        [style]="{'width':'100%'}">
        <ng-template pTemplate="item" let-citem>
            {{citem.parte.parte}} - {{citem.reparacion}}
        </ng-template>
    </p-dropdown>
    <label for="fl-repa">Reparaciones</label>
</span>
<p-button icon="pi pi-plus" iconPos="left" *ngIf="addNewEnable && !showForm" (click)="onAddNewClick($event)">
</p-button>

<!-- <p-panel class="cat-form-popup" *ngIf="showForm" header="Nueva Reparacion"> -->
<p-dialog [(visible)]="showForm" header="Nueva reparación" [modal]="true" [draggable]="false"
    position="right" [closable]="true" contentStyleClass="dialog-content-style" [style]="{'width':'100%', 'height':'80%'}">
    <axks-reparacion-form [operationMode]="operationMode" [formFields]="[]" [item]="newItem" [class]="'cmp-form-' + operationMode"></axks-reparacion-form>
    <ng-template pTemplate="footer">
        <p-button label="Guardar" icon="pi pi-save" iconPos="left" (click)="onSaveClick($event)"></p-button>
        &nbsp;
        <p-button label="Cancelar" icon="pi pi-times" iconPos="left" (click)="onCancelClick($event)"></p-button>
    </ng-template>
</p-dialog>
<!-- </p-panel> -->


<p-toast></p-toast>