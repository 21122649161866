<!-- {{item | json}}-->
<span class="p-float-label" style="float: left; width: 60%;">
    <p-dropdown inputId="fl-caract" [(ngModel)]="item.id" [options]="catalog" optionLabel="caracteristica"
        optionValue="id" [autoDisplayFirst]="false" [virtualScroll]="virtualScroll" itemSize="10" [filter]="true"
        filterBy="caracteristica" autoWidth="false" [style]="{'width':'100%'}" showClear="true"
        (onChange)="modificarItemPadre(item)" [baseZIndex]="10002" appendTo="body"></p-dropdown>
    <label for="fl-caract">Característica</label>
</span>
<p-button icon="pi pi-plus" iconPos="left" *ngIf="addNewEnable && !showForm" (click)="onAddNewClick($event)">
</p-button>


<!-- <p-dialog [(visible)]="showForm" [modal]="true" style="height: 100px;" [draggable]="false" [resizable]="false"
    [baseZIndex]="30000" [contentStyleClass]="'dialog-content'"> -->
<p-panel class="cat-form-popup" header="Nueva Característica" *ngIf="showForm">

    <axks-caracteristica-form [item]="newItem" [formFields]="[]" [operationMode]="operationMode">
    </axks-caracteristica-form>
    <ng-template pTemplate="footer">

        <p-button label="Guardar" icon="fa fa-disk" iconPos="left" (click)="onSaveClick($event)"></p-button>
        &nbsp;
        <p-button label="Cancelar" icon="fa fa-close" iconPos="left" (click)="onCancelClick($event)"></p-button>
    </ng-template>

</p-panel>
<!-- </p-dialog> -->


<p-toast></p-toast>