export enum EstatusSolicitud {
    INGRESADA = 1,
    RECIBIDA = 2,
    PROGRAMAR_RECEPCION_EQUIPO = 3,
    ESPERA_EQUIPO = 4,
    EQUIPO_RECIBIDO = 5,
    EN_REVISION = 6,
    DIAGNOSTICO = 7,
    ATENDIDA = 8,
    EQUIPO_ENTREGADO_EMPLEADO = 9,
    FINALIZADA = 10,
    CORREO_GENERADO = 11,
    LICENCIA_INSTALADA = 12,
    SO_INSTALADO = 13,
    PROGRAMA_SESION_S_O = 14
}

export enum TipoSolicitud {
    ALTA_CORREO_ELECTRONICO = 1,
    REVISION_EQUIPO = 2,
    INSTALACION_SOFTWARE = 3,
    INSTALACION_SISTEMA_OPERATIVO = 4
}