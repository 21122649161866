import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient } from '@axks/net';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EstadoRestcService extends ARestClient{

  constructor(httpClient: HttpClient) {
    super(httpClient);
   }

  getBaseEndpoint(): string {
    let path = environment.msEstado;

    if(!path.endsWith("/")) {
      path += "/";
    }

    return path;
  }

  processBody(res: any) {
    return res || {};
  }
}
