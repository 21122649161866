import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';

import { EmpleadoPsService } from 'src/app/procs/empleado/empleado-ps.service';
import { EmpleadoNombre } from 'src/app/api/empleado';
import { AsignaEquipoReg, Equipo } from 'src/app/api/equipo';
import { AsignaPsService } from 'src/app/procs/equipo/asigna-ps.service';
import { Tecnico } from 'src/app/api/tecnico';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'axks-asigna-dialog',
  templateUrl: './asigna-dialog.component.html',
  styleUrls: ['./asigna-dialog.component.scss']
})
export class AsignaDialogComponent extends BaseView implements OnInit {

  @Input() display: boolean = false;
  @Input() equipo: Equipo;

  @Output() onHide = new EventEmitter<any>();

  item: AsignaEquipoReg;
  yearRange: any;
  iconFaFileImport = faFileImport;
  virtualScroll: boolean = false;
  empleados: EmpleadoNombre[];

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private empleadoPs: EmpleadoPsService,
    private asignaPs: AsignaPsService
  ) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {

    this.empleados = [] as EmpleadoNombre[];

    this.item = {} as AsignaEquipoReg;

    this.initDates();
    this.initCatEmpleados();

    let profile = this.getProfile() as ProfileC;
    let tecnico = profile["tecnico"] as Tecnico;
    this.item.idTecAsigna = tecnico.id;
  }

  initCatEmpleados() {
    this.empleadoPs.empleadosContratoActivo(this.profile.empresa).subscribe(
      (data) => {
        this.empleados = data as EmpleadoNombre[];
      },
      (error) => {
        this.errorMessage("Error. Empleados", "No se pudo consultar la lista de empleados");
      }
    )
  }

  initDates() {
    let now = new Date();
    let year = now.getFullYear();

    this.yearRange = (year - 10) + ':' + (year + 1);
  }

  dialogSize(): any {
    if (this.isMobile()) {
      return { width: '90vw', height: '90%' };
    }

    return { width: '60vw', height: '60%' };
  }

  gridSize(): string {
    if (this.isMobile()) {
      return 'height:76vh';
    }

    return 'height:46vh';
  }

  onHideDialog(event) {
    this.onHide.emit();
  }

  onAsignar($event) {

    this.item.idEquipo = this.equipo.id;
    this.asignaPs.register(this.item).subscribe(
      (data) => {
        this.display = false;
      },
      (error) => {
        console.debug("No se asigno el equipo: ");
        console.debug(error);
      }
    )
  }

  onCancelar($event) {
    this.display = false;
  }


}
