import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppClient, SessionClientProvider } from '@axks/components';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { TecnicoRestcService } from 'src/app/client/tecnico/tecnico-restc.service';
import { Tecnico } from './api/tecnico';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'axks-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends AppClient {
  title = 'soporte-gui';

  private ROL_SOPORTE = 4;

  constructor(
    protected router: Router,
    protected messageService: MessageService,
    protected sessionProv: SessionClientProvider,
    private tecnicoClient: TecnicoRestcService,
    @Inject(DOCUMENT) private docs: Document) {
    super(environment, router, messageService, sessionProv, docs);

    this.initTecnico();

  }

  initTecnico() {

    setTimeout(() => {
      let profile = this.sessionProv.getProfile();

      profile.rol.forEach((rol) => {
        if (rol.idRol == this.ROL_SOPORTE) {
          let idEmpleado = profile.idEmpleado;
          this.tecnicoClient.findByIdEmpleado(idEmpleado).subscribe(
            (data) => {
              let tec = data as Tecnico;
              profile["tecnico"] = tec;
              // console.log("Técnico localizado");
              // console.log(tec)
              // console.log(data);
              this.sessionProv.profile.next(profile);
            }
          );
        }
      });
        
      }, 5000);


      /*  this.sessionProv.profile.subscribe(
         (data) => {
           let profile = data as ProfileC;
           profile.rol.forEach((rol) => {
             if (rol.idRol == this.ROL_SOPORTE) {
               let idEmpleado = profile.idEmpleado;
               this.tecnicoClient.findByIdEmpleado(idEmpleado).subscribe(
                 (data) => {
                   let tec = data as Tecnico;
                   profile["tecnico"] = tec;
                   this.sessionProv.profile.unsubscribe();
                   this.sessionProv.profile.next(profile);
                   
                 }
               );
             }
           }
           )
         }
       ); */

  }



}
