import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { EmpleadoMin, EmpleadoNombre } from 'src/app/api/empleado';
import { Solicitud, SolicitudMin, SolicitudReg } from 'src/app/api/solicitud/solicitud';
import { EmpleadoPsService } from 'src/app/procs/empleado/empleado-ps.service';
import { SeguimientoService } from 'src/app/procs/solicitud/observable/seguimiento.service';
import { SolicitudPsService } from 'src/app/procs/solicitud/solicitud-ps.service';

@Component({
  selector: 'axks-solicitud',
  templateUrl: './solicitud.component.html',
  styleUrls: ['./solicitud.component.scss']
})
export class SolicitudComponent extends BaseMainComponent<SolicitudPsService> implements OnInit {

  modoSoporte: boolean = false;
  redirect: boolean = false;
  urlParams = new URLSearchParams(window.location.search);
  styleRight;
  mostrarDetalle: boolean = false;
  catEmpleados: EmpleadoNombre[];

  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private solicitudPs: SolicitudPsService,
    private router: Router,
    private segObs: SeguimientoService,
    private empleadoPs: EmpleadoPsService) {
    super(deviceService, messageService, screenModeService, sessionProvier);

    let url = this.router.url;

    // Se obtiene el parametro 'r' para indicar que se carga la pagina de un redireccionamiento del dashboard
    if (this.urlParams.get('r')) {
      this.redirect = true;
    }

    this.modoSoporte = url.includes("solicitud-tec") ? true : false;

    this.segObs.recargaHistoria.subscribe(
      (data) => {
        if (data == true) {
          this.rowSelect(this.item.minimumItem);
        }
      });
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.redirect) {
      setTimeout(() => {
        this.initDetalle();
      }, 1);
    }

    if (this.modoSoporte) {
      this.initCatEmpleados();
    }
  }

  initCatEmpleados() {
    this.empleadoPs.empleadosContratoActivo(this.profile.empresa).subscribe(
      (data) => {
        this.catEmpleados = data;
      },
      (error) => {
        this.errorMessage("Error Catalogo Empleados", "No se pudo obtener la lista de empleados.");
      }
    );
  }

  initEmptyItem(): ComponentItem {
    let profile = this.getProfile() as ProfileC;
    let empty = {
      item: {
        idEmpleado: this.modoSoporte ? null : profile.idEmpleado,
        idEmpresa: profile.empresa,
        modoSoporte: this.modoSoporte
      } as Solicitud,
      minimumItem: {
        idEmpleado: this.modoSoporte ? null : profile.idEmpleado,
        idEmpresa: profile.empresa,
        modoSoporte: this.modoSoporte
      } as Solicitud,
      registerItem: {
        idEmpleado: profile.idEmpleado,
        idEmpresa: profile.empresa
      } as SolicitudReg
    } as ComponentItem;
    return empty;
  }

  getService(): SolicitudPsService {
    return this.solicitudPs;
  }

  getColListDefinition(): ColumnDefinition[] {
    let cols = [
      { field: 'id', header: 'No. Solicitud', inDetail: true, inResultList: true },
      { field: 'solicitud', header: 'Solicitud', inAll: true },
      { field: 'tipoSolicitud', header: 'Tipo de solicitud', inAll: true },
      { field: 'equipo.nombre', header: 'Equipo', inDetail: true, inResultList: false },
      { field: 'nombreEmpleado', header: 'Empleado', inDetail: true, inResultList: true },
      { field: 'fecSolicitud', header: 'Fecha Solicitud', inAll: true, formatFunction: this.formatFecha },
      { field: 'fecFinSolicitud', header: 'Fecha Fin Solicitud', inAll: true, formatFunction: this.formatFecha }
    ] as ColumnDefinition[]

    return cols;
  }

  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }

  getComponentTitle(): string {
    if (this.modoSoporte) {
      return 'Soporte. Solicitudes'
    }
    else {
      return 'Soporte. Mis Solicitudes'
    }
  }

  componentsFields() {
    super.componentsFields();
    this.formFields = ["solicitud", "idTipoSolicitud", "idEmpleado", "idEmpresa", "idEquipo"];
    this.formSearch = ["fecSolicitud", "idTipoSolicitud", "idEstatus", "idEmpleado", "catEmpleados"];
  }

  initDetalle() {
    this.screenModeService.screenMode.next(OperationMode.SELECTED);
    let itemMin = {
      id: parseInt(this.urlParams.get('sid'))
    } as SolicitudMin;
    this.rowSelect(itemMin);
    this.mostrarDetalle = true;
  }

  cancel(event: any): void {
    this.mostrarDetalle = false;
    super.cancel(event);
    this.urlParams.delete('r');
    this.urlParams.delete('sid');
    window.history.replaceState(null, document.title, window.location.origin + window.location.pathname + '?' + this.urlParams.toString())
  }

  formatFecha(value: string): string {
    return value ? formatDate(value, "dd 'de' MMMM 'de' yyyy, hh:mm a", 'es-MX') : null;
  }

  save(event: any): void {
    if(this.item.registerItem.solicitud && this.item.registerItem.solicitud.trim() != "" && this.item.registerItem.idTipoSolicitud) {
      super.save(event);
    }
    else {
      this.infoMessage("Información faltante", "Llenar los campos requeridos");
    }
  }

}
