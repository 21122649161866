import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CaracteristicaRestcService extends ARestClient {

  constructor(client: HttpClient) {
    super(client);
   }

  getBaseEndpoint(): string {
    return environment.msCaracteristica+"/";
  }
  processBody(res: any) {
    return res || {};
  }
  getRestPaths(): RestBasicPaths{
    let restPaths = super.getRestPaths();
    restPaths.detail='';
    restPaths.remove='remove';
    return restPaths;
  }

}
