<p-card class="crd-accion-seg" *ngIf="idTipoSolicitud == TP_SOLICITUD.INSTALACION_SISTEMA_OPERATIVO">

    <ng-container
        *ngIf="seguimiento.estatus.idEstatus == EST_SOLICITUD.INGRESADA || seguimiento.estatus.idEstatus == EST_SOLICITUD.RECIBIDA">
        <div class="p-grid">
            <div class="p-col-12">
                <p> Solicitar al empleado que indique el día que traerá el equipo para su revisión.</p>
            </div>
            <!-- <div class="p-col-12">
                <textarea id="fl-coment" pInputTextarea [(ngModel)]="comentario" [rows]="3" [cols]="40"
                    [autoResize]="false" [style]="{'width':'100%'}" placeholder="Comentario"></textarea>
            </div> -->
        </div>

        <ng-template pTemplate="footer">
            <p-button label="Solicitar equipo" icon="pi pi-desktop" [loading]="isLoading" (onClick)="onSolicitudRecibidaAct()"></p-button>
        </ng-template>
    </ng-container>

    <!-- ESPERA_EQUIPO -->
    <ng-container *ngIf="seguimiento.estatus.idEstatus == EST_SOLICITUD.ESPERA_EQUIPO">
        <div class="p-grid">
            <div class="p-col-12">
                <p> Describir el estado del equipo al momento de recibirlo.</p>
            </div>
            <div class="p-col-12">
                <textarea id="fl-coment" pInputTextarea [(ngModel)]="comentario" [rows]="3" [cols]="40"
                    [autoResize]="false" [style]="{'width':'100%'}" placeholder="Comentario"></textarea>
            </div>
        </div>

        <ng-template pTemplate="footer">
            <p-button label="Recibir equipo" [loading]="isLoading" (onClick)="onEsperaEquipoAct()"></p-button>
        </ng-template>
    </ng-container>

     <!-- EQUIPO_RECIBIDO -->
     <ng-container *ngIf="seguimiento.estatus.idEstatus == EST_SOLICITUD.EQUIPO_RECIBIDO">
        <div class="p-grid">
            <div class="p-col-12">
                <p>Registrar que sistema operativo se instaló y el motivo de instalación</p>
            </div>
            <div class="p-col-12">
                <textarea id="fl-coment" pInputTextarea [(ngModel)]="comentario" [rows]="3" [cols]="40"
                    [autoResize]="false" [style]="{'width':'100%'}" placeholder="Comentario"></textarea>
            </div>
        </div>

        <ng-template pTemplate="footer">
            <p-button label="S.O. instalado" icon="pi pi-cog" [loading]="isLoading" (onClick)="onEquipoRecibidoAct()"></p-button>
        </ng-template>
    </ng-container>
</p-card>


<p-toast></p-toast>