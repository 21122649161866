import { Component, OnInit } from '@angular/core';

import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { TipoEquipo } from 'src/app/api/equipo';
import { Parte } from 'src/app/api/parte';
import { PartePsService } from '../../../procs/parte/parte-ps.service';

/**
 * Main component for technical support status.
 * 
 * @author
 */

@Component({
  selector: 'axks-parte',
  templateUrl: './parte.component.html',
  styleUrls: ['./parte.component.scss'],
  providers: [MessageService]
})
export class ParteComponent extends BaseMainComponent<PartePsService> implements OnInit {


  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private psService: PartePsService) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initEmptyItem(): ComponentItem {
    return {
      item: { tipoEquipo:{} as TipoEquipo} as Parte,
      minimumItem: { tipoEquipo:{} as TipoEquipo} as Parte,
      registerItem: { tipoEquipo:{} as TipoEquipo} as Parte
    } as ComponentItem;
  }

  getService(): PartePsService {
    return this.psService;
  }
  getColListDefinition(): ColumnDefinition[] {
    return [
      { header: 'Clave', field: 'idParte', inDetail: true, inResultList: true },
      { header: 'Parte', field: 'parte', inAll: true },
      { header: 'Tipo Equipo', field:'tipoEquipo.tipoEquipo', inAll:true}


    ] as ColumnDefinition[];
  }
  getIdOfSelectedItem(): number {
    return this.item.minimumItem.idParte || this.item.item.idParte
  }
  getComponentTitle(): string {
    return "Parte de dispositivo";
  }
}
