import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageCodes, OperationMode, OperationScreenModeService } from '@axks/components';
import { MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Observable, Subscription } from 'rxjs';
import { Parte } from 'src/app/api/parte';
import { PartePsService } from 'src/app/procs/parte/parte-ps.service';

@Component({
  selector: 'axks-parte-cat',
  templateUrl: './parte-cat.component.html',
  styleUrls: ['./parte-cat.component.scss']
})
export class ParteCatComponent implements OnInit, OnChanges, OnDestroy {

  catalog: Parte[];
  virtualScroll: boolean = false;

  showForm: boolean = false;
  newItem: Parte;

  @Input() value: Parte;
  /**
   * Habilita la funcionalidad para agregar
   */
  @Input() addNewEnable: boolean = false;

  /** 
   * Indica si hay que tomar en cuenta el modo de operación 
   * de la pantalla.
   */
  @Input() operationMode: OperationMode;


  @Input() idTipoEquipo: number;
  @Output() onChange = new EventEmitter<number>();


  constructor(private partePs: PartePsService,
    private messages: MessageService,
    private route: ActivatedRoute) {


  }
  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    if (!this.value) {
      this.value = {} as Parte;
    }
    if (!this.addNewEnable) {
      this.addNewEnable = false;
    }

    if (this.operationMode) {
      this.enableAddNewButton();
    }

    // Temporal mientras se desarrolla
    this.route.data.subscribe((data) => {
      this.addNewEnable = data.addNewEnable;
      this.idTipoEquipo = data.idTipoEquipo;
      this.populate();
    })

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.populate();
  }

  enableAddNewButton() {
    switch (this.operationMode) {
      case OperationMode.ADDNEW:
      case OperationMode.EDIT:
        this.addNewEnable = true;
        break;
      default:
        this.addNewEnable = false;
        break;
    }
  }

  populate() {
    let filter = {} as Parte;
    filter.idTipoEquipo = this.idTipoEquipo;


    this.virtualScroll = false;
    this.partePs.find(filter).subscribe(
      (data) => {
        this.catalog = data as Parte[];
        if (this.catalog.length > 10) {
          this.virtualScroll = true;
        }
      },
      (error) => {
        try {

          let message = error.error as Message;
          this.processMessage(message);
        } catch (e) {
          console.debug("Error")
          console.debug(error)
        }
      }
    );
  }

  processMessage(message: Message) {
    let messText: string;
    let sevMes = "info";
    switch (message.code) {
      case MessageCodes.NOT_FOUND_RESULTS.valueOf():
        messText = "No se localizaron las partes de los equipos. Deberá registrarlos primero.";
        break;
      default:
        messText = "Error desconocido.";
        sevMes = "warn"
        console.debug(message);
        break;
    }

    this.messages.add({ severity: sevMes, summary: "Catálogo de partes de equipo", detail: messText });
  }

  selectOption(event, value) {
    this.onChange.emit(value.id);
  }

  onAddNewClick(event) {
    this.operationMode = OperationMode.ADDNEW;
    this.showForm = true;
    this.newItem = { tipoEquipo: { id: this.idTipoEquipo }, idTipoEquipo: this.idTipoEquipo } as Parte;
  }

  onSaveClick(event) {
    this.partePs.register(this.newItem).subscribe(
      (data) => {
        this.populate();
        this.showForm = false;
        this.newItem = undefined;
      }, (error) => {
        try {
          let message = error.error as Message;
          this.processMessage(message);
        } catch (e) {
          console.debug("Error")
          console.debug(error)
        }
        this.showForm = false;
        this.newItem = undefined;
      });
  }

  onCancelClick(event) {
    this.showForm = false;
    this.newItem = undefined;
  }

}
