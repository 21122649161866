import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { empty } from 'rxjs';
import { Caracteristica } from 'src/app/api/caracteristica'
import { TipoEquipo } from 'src/app/api/equipo';
import { TipoEquipoRestcService } from 'src/app/client/tipo-equipo/tipo-equipo-restc.service';

@Component({
  selector: 'axks-caracteristica-form',
  templateUrl: './caracteristica-form.component.html',
  styleUrls: ['./caracteristica-form.component.scss']
})
export class CaracteristicaFormComponent extends BaseView implements OnInit {

  OP_MODE : OperationMode;

  @Input() item : Caracteristica;

  catTipoEquipo: TipoEquipo[];

  constructor(protected screenModeService: OperationScreenModeService, 
    protected deviceService: DeviceDetectorService, 
    protected messageService: MessageService, 
    protected sessionProvier: SessionClientProvider,
    protected clientTpEquipo: TipoEquipoRestcService) { 

    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if(!this.item){
      this.item = {};
    }

    this.initCatTpEquipo();
  }

  initCatTpEquipo() {
    this.clientTpEquipo.getAll().subscribe(
      (data) => {
        this.catTipoEquipo = data;
      },
      (error) => {
        this.errorMessage("Error","No se pudo obtener el catalogo");
      }
    );
  }

}
