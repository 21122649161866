import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider, OperationMode } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Marca } from 'src/app/api/marca';

@Component({
  selector: 'axks-marca-form',
  templateUrl: './marca-form.component.html',
  styleUrls: ['./marca-form.component.scss']
})
export class MarcaFormComponent extends BaseView implements OnInit {

  @Input() item: Marca = {};
  @Input() operationMode: OperationMode;

  constructor(protected screenModeService: OperationScreenModeService, 
    protected deviceService: DeviceDetectorService, 
    protected messageService: MessageService, 
    protected sessionProvier: SessionClientProvider) {
      super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if(!this.item){
      this.item = {}as Marca;
    }
  }

}
