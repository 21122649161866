<div class="progress container" *ngIf="!loadContent || showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        strokeWidth="8"></p-progressSpinner>
</div>

<ng-container *ngIf="loadContent">
    <!-- {{item.item | json}} -->
    <!-- {{profile | json}} -->

    <axks-operations-bar (addNew)="addNew($event)" addNewLabel="Registrar" [addNewButton]="true" searchLabel="Buscar"
        [searchButton]="true" [sectionTitle]="getComponentTitle()" [addNewIcon]="true" [searchIcon]="true">
    </axks-operations-bar>

    <div class="p-grid detail-sect" *ngIf="showDetail()">
        <div class="p-sm-12 p-md-6 p-lg-6 p-xl-6 p-col-12">
            <axks-detail-tab [data]="workItem()" [colsDefinition]="colDefinition" [detailFields]="detailFields"
                [tabDefs]="tabDefs">
            </axks-detail-tab>
        </div>

        <div class="p-sm-12 p-md-6 p-lg-6 p-xl-6 p-col-12">
            <axks-asigna-historia *ngIf="workItem().historico" [historico]="workItem().historico">
            </axks-asigna-historia>
        </div>
    </div>

    <!-- {{workItem() | json}} -->
    <axks-equipo-form #cmpForm *ngIf="showForm()" [item]="workItem()" [formSearch]="formSearch"
        [formFields]="formFields" [operationMode]="operationMode" [class]="'cmp-form-' + operationMode"
        (onBuscaDisponibles)="onBuscaDisponibles($event)" [showCaract]="showCardCaract"
        (onHideCaract)="showCardCaract = $event" [profile]="profile">
    </axks-equipo-form>

    <axks-actions-bar (save)="save($event)" (cancel)="cancel($event)" (clean)="clean($event)" (find)="find($event)"
        [findButton]="showFindButton" (print)="print($event)" (edit)="edit($event)" [deleteButton]="false"
        (csvFile)="csvFile($event)" (pdfFile)="pdfFile($event)" (txtFile)="txtFile($event)" saveLabel="Guardar"
        cancelLabel="Cancelar" cleanLabel="Limpiar" [extraButtons]="extraButtons" [printButton]="false">
    </axks-actions-bar>

    <axks-result-list [data]="resultList" [colsDefinition]="colDefinition" (rowSelect)="rowSelect($event)"
        (rowUnselect)="rowUnselect($event)" [listFields]="listFields" *ngIf="showResultList()">
    </axks-result-list>
</ng-container>

<p-toast></p-toast>

<!-- *ngIf="showAsigna" -->
<axks-asigna-dialog *ngIf="showAsigna" [display]="true" [equipo]="item.item" (onHide)="onHideDialog($event)">
</axks-asigna-dialog>

<axks-recibe-dialog *ngIf="showRecibe" [display]="true" [equipo]="item.item" (onHide)="onHideDialogRecibir($event)">
</axks-recibe-dialog>