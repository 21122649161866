<p-card class="crd-accion-seg" *ngIf="idTipoSolicitud == TP_SOLICITUD.INSTALACION_SISTEMA_OPERATIVO">

    <ng-container *ngIf="seguimiento.estatus.idEstatus == EST_SOLICITUD.PROGRAMAR_RECEPCION_EQUIPO">
        <div class="p-grid">
            <div class="p-col-12">
                <p>
                    El equipo de soporte técnico necesita tener físicamente el equipo para la instalación del sistema
                    operativo.
                    Favor de indicar el día y hora que se llevara el equipo para la instalación.
                </p>
            </div>
            <div class="p-col-12">
                <textarea id="fl-coment" pInputTextarea [(ngModel)]="comentario" [rows]="3" [cols]="40"
                    [autoResize]="false" [style]="{'width':'100%'}"
                    placeholder="Mensaje para soporte técnico"></textarea>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <p-button label="Programar recepción del equipo" icon="pi pi-send" [loading]="isLoading"
                (onClick)="onProgramaRecepcionEquipo()"></p-button>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="seguimiento.estatus.idEstatus == EST_SOLICITUD.SO_INSTALADO">
        <div class="p-grid">
            <div class="p-col-12">
                <p>
                    Si el sistema operativo se instalo de manera correcta favor de finalizar la solicitud. Si no se
                    finaliza, la
                    solicitud
                    se cerrara automaticamente 7 días despues de ser atendida.
                    En caso de requerir atención y si la solicitud ya se cerro, tendra que abrir otra solicitud.
                </p>
            </div>
        </div>

        <ng-template pTemplate="footer">
            <p-button label="Finalizar solicitud" icon="pi pi-check-circle" [loading]="isLoading"
                (onClick)="onSOInstaladoAct()"></p-button>
        </ng-template>
    </ng-container>

</p-card>

<p-toast></p-toast>