import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { Observable } from 'rxjs';
import { Tecnico } from 'src/app/api/tecnico';
import { TecnicoRestcService } from 'src/app/client/tecnico/tecnico-restc.service';

@Injectable({
  providedIn: 'root'
})
export class TecnicoPsService extends BaseProcess<TecnicoRestcService>{
  
  client : TecnicoRestcService;

  constructor(httpClient: HttpClient, crest: TecnicoRestcService) {
    super(httpClient, crest);
    this.client = crest;
   }

   darBajaTecnico(idTecnico: number): Observable<Tecnico>{
     return this.client.darBajaTecnico(idTecnico);
   }
}
