import { Injectable } from '@angular/core';

import { BaseProcess,} from '@axks/components';
import { HttpClient } from '@angular/common/http';

import { TipoSolicitudRestcService } from 'src/app/client/tipo-solicitud/tipo-solicitud-restc.service';

/**
 * Technical support request of service processor.
 * 
 * @author Juan Godínez
 */

@Injectable({
  providedIn: 'root'
})
export class TipoSolicitudPsService extends BaseProcess<TipoSolicitudRestcService>{

  constructor(httpClient: HttpClient,
    crest: TipoSolicitudRestcService) { 
      super(httpClient, crest);
    }
  

}