<!-- <div style="position: fixed; padding-bottom: 30px;">
    <p-button type="button" icon="pi pi-plus" (click)="insertar($event)" pTooltip="Agrega otra característica"
        tooltipZIndex="20000">
    </p-button>
</div> -->
<!-- {{equipo | json}} -->
<ng-container *ngFor="let row of equipo.caracteristicas">
    <br>
    <p-card [style]="{'padding':'5px'}" [baseZIndex]="10001">
        <div class="p-grid">
            <div class="p-sm-12 p-md-5 p-lg-5 p-xl-5 p-col-12">
                <br>
                <axks-caracteristica-cat [caractPadre]="row" [operationMode]="operationMode" [item]="row.idCaracteristica"
                    [idTipoEquipo]="idTipoEquipo"></axks-caracteristica-cat>
            </div>
            <div class="p-sm-12 p-md-5 p-lg-5 p-xl-5 p-col-12">
                <br>
                <span class="p-float-label">
                    <input type="text" id="inputValor" pInputText [(ngModel)]="row.valor" [style]="{'width':'80%'}">
                    <label for="inputValor">Descripción</label>
                </span>
            </div>
            <div class="p-sm-12 p-md-2 p-lg-2 p-xl-2 p-col-12"
                [style]="{'justify-content': 'center', 'display': 'flex', 'align-items': 'center'}">
                <br>
                <button pButton type="button" (click)="quitar(row)" icon="pi pi-trash" class="p-button-danger"
                    pTooltip="Eliminar" tooltipZIndex="20000">
                </button>
            </div>
        </div>
    </p-card>
</ng-container>