import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Parte } from 'src/app/api/parte';

@Component({
  selector: 'axks-parte-form',
  templateUrl: './parte-form.component.html',
  styleUrls: ['./parte-form.component.scss']
})
export class ParteFormComponent extends BaseView implements OnInit {

  @Input() item: Parte = {};


  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {} as Parte;
    }
  }

  onChangeTipoEquipo(event){
    this.item.idTipoEquipo = event;
  }

}
