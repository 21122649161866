import { Injectable } from '@angular/core';

import { BaseProcess} from '@axks/components';
import { HttpClient } from '@angular/common/http';

import { MarcaRestcService } from 'src/app/client/marca/marca-restc.service';

/**
 * Technical support device Trademarks processor.
 * 
 * @author Juan Godínez
 */
@Injectable({
  providedIn: 'root'
})
export class MarcaPsService extends BaseProcess<MarcaRestcService>{

  constructor(httpClient: HttpClient,
    crest: MarcaRestcService) {
    super(httpClient, crest);
  }

}