import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { TipoEquipo } from 'src/app/api/equipo';

@Component({
  selector: 'axks-tipo-equipo-form',
  templateUrl: './tipo-equipo-form.component.html',
  styleUrls: ['./tipo-equipo-form.component.scss']
})
export class TipoEquipoFormComponent extends BaseView implements OnInit {

  @Input() operationMode: OperationMode;

  @Input() item: TipoEquipo;

  constructor(protected screenModeService: OperationScreenModeService, 
    protected deviceService: DeviceDetectorService, 
    protected messageService: MessageService, 
    protected sessionProvier: SessionClientProvider) { 

    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if(!this.item){
      this.item = {};
    }
  }

}
