import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Reparacion } from 'src/app/api/solicitud/reparacion';

@Component({
  selector: 'axks-reparacion-form',
  templateUrl: './reparacion-form.component.html',
  styleUrls: ['./reparacion-form.component.scss']
})
export class ReparacionFormComponent extends BaseView implements OnInit {

  @Input() item: Reparacion;
  @Input() idTipoEquipo: number;

  constructor(screenModeService: OperationScreenModeService,
    deviceService: DeviceDetectorService,
    messageService: MessageService,
    sessionProvier: SessionClientProvider) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = { parte: { tipoEquipo: {} } } as Reparacion;
    }

    try {
        this.item.parte.idTipoEquipo = this.item.parte.tipoEquipo.id;

    } catch (e) { }
  }

  onChangeTipoEquipo(event) {
    this.item.parte.idTipoEquipo = event;
  }



}
