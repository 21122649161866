<!-- {{seguimiento | json}} -->
<!-- <ng-container *ngIf="seguimiento.estatus.idEstatus == ESTATUS.INGRESADA"> -->
<div class="p-grid">
    <div class="p-sm p-md-12 p-lg-12 p-xl-12 p-col-12">
        <div class="det-field-lbl">Estatus:</div>
        <div class="det-field-val">
            {{seguimiento.estatus.estatus}}
        </div>
    </div>
    <div class="p-sm p-md-12 p-lg-12 p-xl-12 p-col-12">
        <!-- <div class="p-grid"> -->
            <!-- <div class="p-col-6"> -->
                <div class="det-field-lbl">Fecha estatus:</div>
            <!-- </div> -->
            <!-- <div class="p-col-6"> -->
                <div class="det-field-val">
                    {{seguimiento.fecMov | date : "dd 'de' MMMM 'de' yyyy, hh:mm a" : undefined : "es-MX"}}
                </div>
            <!-- </div> -->
        <!-- </div> -->
    </div>
    <div class="p-sm p-md-12 p-lg-12 p-xl-12 p-col-12" *ngIf="seguimiento.tecnico">
        <div class="det-field-lbl">Atendió técnico:</div>
        <div class="det-field-val">
            {{seguimiento.tecnico.nombreCompleto}}
        </div>
    </div>
    <div class="p-sm p-md-12 p-lg-12 p-xl-12 p-col-12">
        <span class="det-field-lbl">Comentario:</span>
        <span class="det-field-val">
            {{seguimiento.comentario}}
        </span>

    </div>
    <div class="p-sm p-md-12 p-lg-12 p-xl-12 p-col-12" *ngIf="seguimiento.diagnostico">
        <p-panel header="Fallas">
            <div class="p-grid">
                <div class="p-sm-6 p-md-6 p-lg-5 p-xl-4 p-col-6">
                    <!--  titulo-tbl"> -->
                    <span style="font-weight: bold;">Falla</span>
                </div>
                <div class="p-sm-6 p-md-6 p-lg-7 p-xl-8 p-col-6">
                    <span style="font-weight: bold;">Descripción</span>
                </div>
                <ng-container *ngFor="let diag of seguimiento.diagnostico">
                    <div class="p-sm-6 p-md-6 p-lg-5 p-xl-4 p-col-6" style="overflow-wrap: anywhere;">
                        <!-- det-field-lbl"> -->
                        <span class="span-header">{{diag.falla.falla}}</span>
                    </div>
                    <div class="p-sm-6 p-md-6 p-lg-7 p-xl-8 p-col-6" style="overflow-wrap: anywhere;">
                        <!--  det-field-val"> -->
                        <span class="span-content">{{diag.comentario}}</span>
                    </div>
                </ng-container>
            </div>
        </p-panel>
    </div>
</div>
<!-- </ng-container> -->