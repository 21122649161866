<p-card class="crd-accion-seg" *ngIf="idTipoSolicitud == tipoSolicitud.ALTA_CORREO_ELECTRONICO">

  <ng-container *ngIf="seguimiento.estatus.idEstatus == ESTATUS.CORREO_GENERADO">
    <div class="p-grid">
      <div class="p-col-12">
        <span>
          Si el correo se genero de manera correcta favor de finalizar la solicitud. Si no la finaliza, la
          solicitud se cerrara automaticamente una semana despues de ser atendida.
          En caso de requerir atención y si la solicitud ya se cerro, tendra que abrir otra solicitud.
        </span>
      </div>
    </div>

    <ng-template pTemplate="footer">
      <p-button label="Finalizar solicitud" [loading]="isLoading" (onClick)="onGeneraCorreoAct()"></p-button>
    </ng-template>
  </ng-container>
</p-card>

<p-toast></p-toast>