import { Component, OnInit } from '@angular/core';
import { BaseView, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SolicitudPsService } from 'src/app/procs/solicitud/solicitud-ps.service';
import { Solicitud } from 'src/app/api/solicitud/solicitud';
import { MessageService } from 'primeng/api';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { Roles } from '../../../api/enums/roles';
import { Router } from '@angular/router';

@Component({
  selector: 'axks-solicitudes-nuevas-dash',
  templateUrl: './solicitudes-nuevas-dash.component.html',
  styleUrls: ['./solicitudes-nuevas-dash.component.scss']
})
export class SolicitudesNuevasDashComponent extends BaseView implements OnInit {


  solicitudes: Solicitud[];
  modoSoporte: boolean = false;
  profile = this.getProfile() as ProfileC;
  showContent: boolean;
  myLoadContent: boolean;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private solicitudPs: SolicitudPsService,
    private router: Router) {
    super(screenModeService, deviceService, messageService, sessionProvier);

    this.myLoadContent = false;
  }

  ngOnInit(): void {
    this.getSolicitudesNuevas();
    if(!this.solicitudes){
      this.solicitudes = [];
    }
  }

  initContent() {
    if (this.solicitudes.length > 0) {
      this.showContent = true;
    }
    else {
      this.showContent = false;
    }
  }

  getSolicitudesNuevas() {
    for (let rolItem of this.profile.rol) {
      if (rolItem.idRol == Roles.TECNICO_SOPORTE) {
        this.modoSoporte = true;
      }
    }

    let filtro = {
      modoSoporte: this.modoSoporte,
      idEmpleado: this.modoSoporte ? null : this.profile.idEmpleado
    } as Solicitud;

    this.solicitudPs.find(filtro).subscribe(
      (data) => {
        let sols = data as Solicitud[];
        sols.forEach(s => {
          if (!s.fecFinSolicitud) {
            this.solicitudes.push(s);
          }
        });
        this.initContent();
        this.myLoadContent = true;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo consultar las solicitudes de soporte");
        this.myLoadContent = true;
      }
    );
  }

  selectSolicitud(event) {
    // this.router.navigate(["solicitud-tec"], {
    //   queryParams: {
    //     "e": this.profile.empresa,
    //     "a": this.profile.aplicacion,
    //     "s": this.profile.sesion,
    //     "r": true,
    //     "sid": event.value.id
    //   }
    // });
    let urlParams = new URLSearchParams(window.location.search);
    urlParams.set('r', 'true');
    urlParams.set('sid', event.value.id);
    let urlRedirect = window.location.origin + "/solicitud-tec" + "?" + "e=" + urlParams.get('e') + "&a=" + urlParams.get('a') + "&s=" + urlParams.get('s') + "&r=" + urlParams.get('r') + "&sid=" + urlParams.get('sid');
    window.parent.postMessage({ for: "redirect", url: urlRedirect }, "*");
  }

}