import { Injectable } from '@angular/core';

import { BaseProcess } from '@axks/components';
import { HttpClient } from '@angular/common/http';
import { EquipoRestcService } from 'src/app/client/equipo/equipo-restc.service';
import { Observable } from 'rxjs';
import { Equipo, EquipoMin, FiltroEquipo } from 'src/app/api/equipo';

/**
 * Technical support Device processor.
 * 
 * @author Juan Godínez
 */

@Injectable({
  providedIn: 'root'
})
export class EquipoPsService extends BaseProcess<EquipoRestcService>{

  equipoClient: EquipoRestcService;
  constructor(httpClient: HttpClient,
    crest: EquipoRestcService) {
    super(httpClient, crest);
    this.equipoClient = crest;
  }

  asignadosAEmpleado(idEmpleado: number): Observable<EquipoMin[]>{
    return this.equipoClient.findAsignadosEmpleado(idEmpleado); 
  }

  equiposDisponibles(): Observable<Equipo[]> {
      return this.equipoClient.getDisponibles();
  }

}