<div class="progress container" *ngIf="!loadContent || showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        strokeWidth="8"></p-progressSpinner>
</div>

<ng-container *ngIf="loadContent">
    <!-- {{item.item | json}} -->
    <axks-operations-bar (addNew)="addNew($event)" addNewLabel="Registrar " [addNewButton]="!modoSoporte"
        searchLabel="Buscar " [searchButton]="!modoSoporte" [sectionTitle]="getComponentTitle()">
    </axks-operations-bar>

    <div class="p-grid" *ngIf="showDetail() || mostrarDetalle">
        <div class="p-sm-12 p-md-6 p-lg-6 p-xl-6 p-col-12 detail-sect">
            <axks-detail-tab class="axks-detail-tab-100" [data]="workItem()" [colsDefinition]="colDefinition"
                [detailFields]="detailFields">
            </axks-detail-tab>
        </div>
        <div class="p-sm-12 p-md-6 p-lg-6 p-xl-6 p-col-12 detail-sect">
            <axks-det-historia *ngIf="item.item.historico" [historico]="item.item.historico" [modoSoporte]="modoSoporte"
                [fecFinSolicitud]="workItem().fecFinSolicitud" [idTipoSolicitud]="workItem().idTipoSolicitud"></axks-det-historia>
        </div>
    </div>

    <axks-solicitud-form #cmpForm *ngIf="showForm() && !mostrarDetalle" [item]="workItem()" [formSearch]="formSearch"
        [formFields]="formFields" [operationMode]="operationMode" [class]="'cmp-form-' + operationMode"
        [modoSoporte]="modoSoporte" [catEmpleados]="catEmpleados">
    </axks-solicitud-form>

    <axks-actions-bar (save)="save($event)" (cancel)="cancel($event)" (clean)="clean($event)" (find)="find($event)"
        [editButton]="false" [deleteButton]="false" [printButton]="false" saveLabel="Guardar" cancelLabel="Cancelar"
        cleanLabel="Limpiar">
    </axks-actions-bar>

    <axks-result-list [data]="resultList" [colsDefinition]="colDefinition" (rowSelect)="rowSelect($event)"
        (rowUnselect)="rowUnselect($event)" [listFields]="listFields" *ngIf="showResultList()">
    </axks-result-list>
</ng-container>

<p-toast></p-toast>