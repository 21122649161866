import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Asignacion } from 'src/app/api/equipo';

@Component({
  selector: 'axks-asigna-historia',
  templateUrl: './asigna-historia.component.html',
  styleUrls: ['./asigna-historia.component.scss']
})
export class AsignaHistoriaComponent extends BaseView implements OnInit {

  @Input() historico: Asignacion[];

  constructor(screenModeService: OperationScreenModeService,
    deviceService: DeviceDetectorService,
    messageService: MessageService,
    sessionProvier: SessionClientProvider) {
    super(screenModeService, deviceService, messageService, sessionProvier)
  }

  ngOnInit(): void {

    if (!this.historico) {
      this.historico = [];
    }

    this.historico = this.historico.sort((a, b) => {
      let dateA = Date.parse(a.fecAsignacion);
      let dateB = Date.parse(b.fecAsignacion);
      return +dateA <= +dateB ? 1 : 0;
    });
  }


}
