import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { Observable } from 'rxjs';
import { AsignaEquipo, RecepcionEquipo } from 'src/app/api/equipo';
import { AsignaRestcService } from 'src/app/client/equipo/asigna-restc.service';

@Injectable({
  providedIn: 'root'
})
export class AsignaPsService extends BaseProcess<AsignaRestcService> {

  restc: AsignaRestcService;
  constructor(httpClient: HttpClient,
    crest: AsignaRestcService) { 
    super(httpClient, crest);
    this.restc = crest;
  }

  recepcionEquipo(recEquipo: RecepcionEquipo): Observable<AsignaEquipo> {
    return this.restc.recibeEquipo(recEquipo);
  }

}
