import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SeguimientoService {

  recargaHistoria = new BehaviorSubject<boolean>(false);
  
  constructor() { }

}
