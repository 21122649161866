import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Seguimiento, SeguimientoReg } from 'src/app/api/solicitud/solicitud';
import { EstatusSolicitud } from 'src/app/api/enums/solicitud.enum';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { SolicitudPsService } from 'src/app/procs/solicitud/solicitud-ps.service';
import { SeguimientoService } from 'src/app/procs/solicitud/observable/seguimiento.service';

@Component({
  selector: 'axks-det-seguimiento',
  templateUrl: './det-seguimiento.component.html',
  styleUrls: ['./det-seguimiento.component.scss']
})
export class DetSeguimientoComponent extends BaseView implements OnInit {

  @Input() seguimiento: Seguimiento;
  @Input() modoSoporte: boolean;

  ESTATUS = EstatusSolicitud;

  constructor(screenModeService: OperationScreenModeService, 
    deviceService: DeviceDetectorService, 
    messageService: MessageService,
    sessionProvier: SessionClientProvider,
    private solPs: SolicitudPsService,
    private segObs: SeguimientoService) { 
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if(!this.seguimiento){
      this.seguimiento = {};
    }
    this.notificaRecepcion();
  }

  notificaRecepcion(){
    let profile = this.getProfile() as ProfileC;

    if(this.modoSoporte && this.seguimiento.estatus.idEstatus == this.ESTATUS.INGRESADA && this.seguimiento.actual == true){
      let seg = {} as SeguimientoReg;
      seg.idSegSolicitud = this.seguimiento.idSegSolicitud;
      seg.idSolicitud = this.seguimiento.idSolicitud;
      seg.idTecnico = profile["tecnico"].id;

      this.solPs.solicitudIngresadaAct(seg).subscribe(
        (data)=>{
          this.segObs.recargaHistoria.next(true);
        }
      );
    }
  }

}
