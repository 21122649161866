<!-- {{historico | json}} -->
<p-tabView>
    <p-tabPanel rightIcon="pi pi-clock" header="Seguimiento de la solicitud"
        [style]="{'padding-top':'1vh', 'padding-bottom': '1vh','display':'grid', 'height':'50vh', 'overflow-x':'hidden', 'overflow-y':'auto'}">
        <p-card [style]="{ 'min-height': '48vh', width: '100%' }">
            <p-timeline [value]="historico" align="right">
                <ng-template pTemplate="content" let-item>
                    <span class="p-text-primary item-button" (click)="onSelSeguimiento($event, item)"
                        (mouseover)="funcionMouseOver($event)" [class]="selectorEstilo"
                        (mouseout)="funcionMouseOut($event)" pTooltip="Haz click para desplegar el detalle del proceso."
                        tooltipPosition="left">{{item.estatus.estatus}}</span>
                    <small class="p-text-secondary" style="color:#34A835"
                        *ngIf="item.actual && (modoSoporte ? item.estatus.operaTecnico : !item.estatus.operaTecnico) && !fecFinSolicitud">
                        <br>Acción Requerida
                    </small>
                </ng-template>
                <ng-template pTemplate="opposite" let-event>
                    <small class="p-text-secondary">{{event.fecMov | date : "dd 'de' MMMM 'de' yyyy, hh:mm a" :
                        undefined : "es-MX"}}</small>
                </ng-template>
            </p-timeline>
        </p-card>
    </p-tabPanel>
</p-tabView>

<p-dialog [(visible)]="displayDialog" *ngIf="displayDialog" [modal]="true" position="right" [style]="dialogStyle()"
    [closable]="true">
    <!-- {{selSeguimiento | json}} -->
    <ng-template pTemplate="header">
        {{selSeguimiento.estatus.estatus}}
    </ng-template>
    <p-panel [toggleable]="true">
        <ng-template pTemplate="header">
            Detalle {{selSeguimiento.estatus.estatus}}
        </ng-template>

        <axks-det-seguimiento [seguimiento]="selSeguimiento" [modoSoporte]="modoSoporte"></axks-det-seguimiento>
    </p-panel>
    <p-panel [toggleable]="true"
        *ngIf="selSeguimiento.actual && (modoSoporte ? selSeguimiento.estatus.operaTecnico : !selSeguimiento.estatus.operaTecnico) && !fecFinSolicitud">
        <ng-template pTemplate="header">
            Acciones a realizar
        </ng-template>

        <!-- Aplica para revisión de equipo -->
        <axks-accion-seguimiento [idTipoSolicitud]="idTipoSolicitud" [seguimiento]="selSeguimiento" *ngIf="!modoSoporte"
            (onFinAccion)="onFinAccion($event)"></axks-accion-seguimiento>
        <axks-accion-tec-seguimiento [idTipoSolicitud]="idTipoSolicitud" [seguimiento]="selSeguimiento" *ngIf="modoSoporte"
            (onFinAccion)="onFinAccion($event)"></axks-accion-tec-seguimiento>
        <!-- Fin revisión de equipo -->

        <!-- Aplica para la generación de correo -->
        <axks-accion-generar-correo [idTipoSolicitud]="idTipoSolicitud" [seguimiento]="selSeguimiento" *ngIf="!modoSoporte"
            (onFinAccion)="onFinAccion($event)"></axks-accion-generar-correo>
        <axks-accion-tec-generar-correo [idTipoSolicitud]="idTipoSolicitud" [seguimiento]="selSeguimiento" *ngIf="modoSoporte"
            (onFinAccion)="onFinAccion($event)"></axks-accion-tec-generar-correo>
        <!-- Fin de generación de correo -->

        <!-- Genera La actualización de la licencia -->
        <axks-accion-licencia [idTipoSolicitud]="idTipoSolicitud" [seguimiento]="selSeguimiento" *ngIf="!modoSoporte" 
            (onFinAccion)="onFinAccion($event)"></axks-accion-licencia>
        <axks-accion-tec-licencia [idTipoSolicitud]="idTipoSolicitud" [seguimiento]="selSeguimiento" *ngIf="modoSoporte"
            (onFinAccion)="onFinAccion($event)"></axks-accion-tec-licencia>
        <!-- Fin de actualizacion -->

        <!-- Reinstalación de sistema operativo -->
        <axks-accion-s-o [idTipoSolicitud]="idTipoSolicitud" [seguimiento]="selSeguimiento" *ngIf="!modoSoporte"
            (onFinAccion)="onFinAccion($event)"></axks-accion-s-o>
        <axks-accion-tec-s-o-instalacion [idTipoSolicitud]="idTipoSolicitud" [seguimiento]="selSeguimiento" *ngIf="modoSoporte"
            (onFinAccion)="onFinAccion($event)"></axks-accion-tec-s-o-instalacion>
        <!-- Finaliza la reinstalación del sistema operativo -->

    </p-panel>
</p-dialog>