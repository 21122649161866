import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { CaracteristicaRestcService } from 'src/app/client/caracteristica/caracteristica-restc.service';

@Injectable({
  providedIn: 'root'
})
export class CaracteristicaPsService extends BaseProcess<CaracteristicaRestcService>{

  constructor(httpClient: HttpClient, crest: CaracteristicaRestcService) {
    super(httpClient, crest);
   }
}
