import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseView, Message, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { EstatusSolicitud, TipoSolicitud } from 'src/app/api/enums/solicitud.enum';
import { Seguimiento, SeguimientoReg } from 'src/app/api/solicitud/solicitud';
import { Tecnico } from 'src/app/api/tecnico';
import { SeguimientoService } from 'src/app/procs/solicitud/observable/seguimiento.service';
import { SolicitudPsService } from 'src/app/procs/solicitud/solicitud-ps.service';
import { LabelPsService } from 'src/app/procs/content/label-ps.service';
import { Diagnostico } from 'src/app/api/solicitud/diagnostico';
import { Atencion } from 'src/app/api/solicitud/atencion';

@Component({
  selector: 'axks-accion-tec-licencia',
  templateUrl: './accion-tec-licencia.component.html',
  styleUrls: ['./accion-tec-licencia.component.scss']
})
export class AccionTecLicenciaComponent extends BaseView implements OnInit {

  ESTATUS = EstatusSolicitud;
  TP_SOLICITUD = TipoSolicitud;

  @Input() seguimiento: Seguimiento;
  @Input() idTipoSolicitud: number;

  @Output() onFinAccion = new EventEmitter();

  fallaDiag: Diagnostico;
  diagnostico: Diagnostico[];

  repAtencion: Atencion;
  actividadesSol: Atencion[];

  comentario?: string;

  tecnico: Tecnico;
  labels: any;

  isLoading: boolean = false;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private solPs: SolicitudPsService,
    private segObs: SeguimientoService,
    private labelPs: LabelPsService
  ) {
    super(screenModeService, deviceService, messageService, sessionProvier);

    this.labelPs.labels.subscribe((data) => { this.labels = data });
  }

  ngOnInit(): void {
    this.tecnico = this.getProfile()["tecnico"];
    this.isLoading = false;
  }

  onSolicitudRecibidaAct(event) {

    this.isLoading = true;

    let seg = {} as SeguimientoReg;
    seg.idSegSolicitud = this.seguimiento.idSegSolicitud;
    seg.idSolicitud = this.seguimiento.idSolicitud;
    seg.comentario = this.comentario;
    seg.idTecnico = this.tecnico.id;
    seg.idTipoSolicitud = this.seguimiento.idTipoSolicitud;
    seg.fecAtencion = this.seguimiento.fecAtencion;

    this.solPs.solicitudRecibidaAct(seg).subscribe(
      (data) => {
        let mess = data as Message;
        this.segObs.recargaHistoria.next(true);
        this.infoMessage("Seguimiento", mess.details);
        this.isLoading = false;
        this.onFinAccion.emit(true);
      },
      (error) => {
        this.isLoading = false;
        this.errorMessage("Error", "No se pudo procesar la solicitud");
      }
    );
  }

}
