import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { EmpleadoMin, EmpleadoNombre } from 'src/app/api/empleado';
import { EquipoMin } from 'src/app/api/equipo';
import { Solicitud } from 'src/app/api/solicitud/solicitud';
import { TipoSolicitud } from 'src/app/api/tipo-solicitud';
import { EquipoPsService } from 'src/app/procs/equipo/equipo-ps.service';
import { TipoSolicitudPsService } from 'src/app/procs/tipo-solicitud/tipo-solicitud-ps.service';
import { EmpleadoPsService } from '../../../procs/empleado/empleado-ps.service';


@Component({
  selector: 'axks-solicitud-form',
  templateUrl: './solicitud-form.component.html',
  styleUrls: ['./solicitud-form.component.scss']
})
export class SolicitudFormComponent extends BaseView implements OnInit {

  @Input() item: Solicitud;
  @Input() modoSoporte: boolean;
  @Input() catEmpleados: EmpleadoNombre[];

  ROL_SOPORTE = 4;
  catTipoSol: TipoSolicitud[];
  catEquiposEmpleado: { id, nombre }[];
  showEquipo: boolean;
  OP_MODE = OperationMode;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private tipoSolPs: TipoSolicitudPsService,
    private equipoPs: EquipoPsService) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {};
    }

    this.showEquipo = false;
    this.tipoSolPs.all().subscribe(
      (data) => {
        this.catTipoSol = data as TipoSolicitud[];
      },
      (error) => {
        this.errorMessage("Error Tipo Solicitud", "No se pudo obtener el tipo de solicitudes");
      }
    );
    if(!this.catEmpleados){
      this.catEmpleados = [];
    }
  }

  hasRolSoporte(): boolean {
    try {
      let profile = this.getProfile() as ProfileC;
      profile.rol.forEach((rol) => {
        if (rol.idRol == this.ROL_SOPORTE) {
          return true;
        }
      })
      return false;
    } catch (e) {
      return false;
    }
  }

  selTipoSolicitud(event) {
    let tpSol = this.catTipoSol.find(ts => ts.idTipoSolicitud == event.value);

    this.showEquipo = tpSol.paraEquipo;

    if (this.showEquipo) {
      let profile = this.getProfile() as ProfileC;
      this.equipoPs.asignadosAEmpleado(profile.idEmpleado).subscribe(
        (data) => {
          //this.catEquiposEmpleado = data as EquipoMin[];
          this.catEquiposEmpleado = [];
          data.forEach((equipo) => {
            let nm = equipo.nombre + " - " + equipo.modelo.modelo;
            this.catEquiposEmpleado.push({ id: equipo.idEquipo, nombre: nm });
          })
        }
      )
    } else {
      this.item.idEquipo = undefined;
    }
  }
}
