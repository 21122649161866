<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('tipoEquipo')">
        <axks-tipo-equipo-cat [(value)]="item.modelo.marca.tipoEquipo" [operationMode]="operationMode">
        </axks-tipo-equipo-cat>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12"
        *ngIf="allowFormFields('marca') && item.modelo.marca.tipoEquipo.id">
        <axks-marca-cat [(value)]="item.modelo.marca" [idTipoEquipo]="item.modelo.marca.tipoEquipo.id"
            [operationMode]="operationMode">
        </axks-marca-cat>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('modelo') && item.modelo.marca.idMarca">
        <axks-modelo-cat [(value)]="item.modelo" [idMarca]="item.modelo.marca.idMarca" [operationMode]="operationMode">
        </axks-modelo-cat>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('noSerie')">
        <br>
        <span class="p-float-label">
            <input id="fl-noserie" type="text" pInputText [(ngModel)]="item.numSerie">
            <label for="fl-noserie">No. Serie</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('fechaAdquisicion')">
        <br>
        <span class="p-float-label">
            <p-calendar id="fl-fecad" [(ngModel)]="item.fechaAdquisicion" dateFormat="yy/mm/dd" [yearNavigator]="true"
                [yearRange]="yearRange" [monthNavigator]="true" dataType="string"></p-calendar>
            <label for="fl-fecad">Fecha de adquisición</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('costo')">
        <br>
        <span class="p-float-label">
            <p-inputNumber [(ngModel)]="item.costo" inputId="fl-costo" mode="currency" currency="MXN" locale="es-MX"
                [style]="{'width': '100%'}">
            </p-inputNumber>
            <label for="fl-costo">Precio (Opcional)</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('noCompra')">
        <br>
        <span class="p-float-label">
            <input id="fl-noserie" type="text" pInputText pKeyFilter="int" [(ngModel)]="item.idCompra">
            <label for="fl-noserie">No. Compra (Opcional)</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg- p-xl-3 p-col-12" *ngIf="operationMode != OP_MODE.ADDNEW">
        <br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-est" [autoDisplayFirst]="false" [options]="estadosEquipo"
                [(ngModel)]="item.estado.id" optionLabel="estadoEquipo" optionValue="id"></p-dropdown>
            <label for="fl-est">Estado</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg- p-xl-3 p-col-12" *ngIf="allowFormFields('disponibilidad')">
        <br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-disp" [autoDisplayFirst]="false" [options]="catDisponibilidad"
                [(ngModel)]="item.disponibilidad"></p-dropdown>
            <label for="fl-disp">Disponibilidad</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg- p-xl-3 p-col-12" *ngIf="allowFormFields('empleados')">
        <br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-emp" [autoDisplayFirst]="false" [options]="empleados"
                [(ngModel)]="item.idEmpleado" optionLabel="nombreCompleto" optionValue="id"
                [filter]="true" filterBy="nombreCompleto"></p-dropdown>
            <label for="fl-emp">Empleado</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('comentario')">
        <br>
        <span class="p-float-label">
            <input id="fl-obs" type="text" pInputText [(ngModel)]="item.comentario">
            <label for="fl-obs">Observaciones (Opcional)</label>
        </span>
    </div>
</div>


<div>
    <p-dialog [(visible)]="showCaract" header="Caracteristicas del equipo" [baseZIndex]="10000" [modal]="true"
        [draggable]="true" [resizable]="false" [maximizable]="true" (onHide)="ocultarCardCaract($event)"
        contentStyleClass="dialog-content-height" position="center">
        <!-- [style]="{'width':'auto', 'max-width': '70vw', 'height':'auto', 'max-height':'70vh'}" -->
        <axks-caracteristica-equipo *ngIf="allowFormFields('form-caracteristicas')"
            [idTipoEquipo]="item.modelo.marca.tipoEquipo.id" [equipo]="item" [operationMode]="operationMode"
            (hideDialog)="showCaract = $event">
        </axks-caracteristica-equipo>
        <ng-template pTemplate="footer">
            <div class="p-grid">
                <div class="p-col-6" style="display: flex; justify-content: left;">
                    <p-button type="button" icon="pi pi-plus" (click)="insertar($event)"
                        pTooltip="Agrega otra característica" tooltipZIndex="20000" [style]="{'width':'35px'}">
                    </p-button>
                </div>
                <div div class="p-col-6" style="display: flex; justify-content: right;">
                    <p-button type="button" icon="pi pi-save" (click)="guardarCaract()" label="Guardar caracteristicas">
                    </p-button>
                </div>
            </div>
        </ng-template>
    </p-dialog>
</div>