import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { Observable } from 'rxjs';
import { EmpleadoNombre } from 'src/app/api/empleado';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmpleadoRestcService extends ARestClient {


  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    return environment.msEmpleado + "/";
  }
  processBody(res: any) {
    return res || {};
  }

  getRestPaths(): RestBasicPaths {
    let paths = super.getRestPaths();
    paths.detail = "";
    paths.remove = "";
    return paths;
  }

  empleadosContratoActivo(idEmpresa: number): Observable<EmpleadoNombre[]> {
    let path = this.getBaseEndpoint() + "empleados-contrato-activo/" + idEmpresa;
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.get(path, { headers }) as Observable<EmpleadoNombre[]>;
  }
}
