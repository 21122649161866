import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FallaRestcService extends ARestClient{

  constructor(client: HttpClient) { 
    super(client)
  }

  getBaseEndpoint(): string {
    return environment.msFalla +"/";
  }

  processBody(res: any) {
    return res || {};
  }

  getRestPaths(): RestBasicPaths {
    let paths = super.getRestPaths();
    paths.remove = "remove";
    paths.detail = "";

    return paths;
  }
}
