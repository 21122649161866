import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Falla } from 'src/app/api/solicitud/falla';
import { FallaPsService } from 'src/app/procs/solicitud/falla-ps.service';

@Component({
  selector: 'axks-falla',
  templateUrl: './falla.component.html',
  styleUrls: ['./falla.component.scss']
})
export class FallaComponent extends BaseMainComponent<FallaPsService> implements OnInit {
  

  constructor(deviceService: DeviceDetectorService,
    messageService: MessageService,
    screenModeService: OperationScreenModeService,
    sessionProvier: SessionClientProvider,
    private fallaPs: FallaPsService) {
    super(deviceService,messageService,screenModeService,sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initEmptyItem(): ComponentItem {
    let empty={
      registerItem: { parte:{tipoEquipo:{}}} as Falla,
      minimumItem: { parte:{tipoEquipo:{}}} as Falla,
      item: { parte:{tipoEquipo:{}}} as Falla
    } as ComponentItem;

    return empty;
  }
  getService(): FallaPsService {
    return this.fallaPs;
  }
  getColListDefinition(): ColumnDefinition[] {
    return [
      {header:'Clave', field:'id', inAll:true} as ColumnDefinition,
      {header:'Falla', field:'falla', inAll:true} as ColumnDefinition,
      {header:'Parte', field:'parte.parte', inAll:true} as ColumnDefinition,
    ] as ColumnDefinition[];
  }
  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }
  getComponentTitle(): string {
    return "Falla";
  }

}
