import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ButtonModel } from '@axks/components/lib/api/views/button-model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { TipoEquipo } from 'src/app/api/equipo';
import { TipoEquipoPsService } from 'src/app/procs/tipo-equipo/tipo-equipo-ps.service';

@Component({
  selector: 'axks-tipo-equipo',
  templateUrl: './tipo-equipo.component.html',
  styleUrls: ['./tipo-equipo.component.scss']
})
export class TipoEquipoComponent extends BaseMainComponent<TipoEquipoPsService> implements OnInit {

  extraButtons: ButtonModel[];

  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    protected tipoEquipoPs: TipoEquipoPsService) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {

    super.ngOnInit();
    this.extraButtons = [];
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {} as TipoEquipo,
      minimumItem: {} as TipoEquipo,
      registerItem: {} as TipoEquipo,
    } as ComponentItem;
    return empty;
  }
  getService(): TipoEquipoPsService {
    return this.tipoEquipoPs;
  }
  getColListDefinition(): ColumnDefinition[] {
    return [
      { field: 'id', header: 'Clave', inResultList: true, inDetail: true },
      { field: 'tipoEquipo', header: 'Tipo de equipo', inAll: true },
      { field: 'prefix', header: "Prefijo para inventario", inAll: true }
    ] as ColumnDefinition[];
  }
  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }
  getComponentTitle(): string {
    return "Tipo de Equipo";
  }


}
