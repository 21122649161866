import { Injectable } from '@angular/core';

import { BaseProcess} from '@axks/components';
import { HttpClient } from '@angular/common/http';

import { ParteRestcService } from 'src/app/client/parte/parte-restc.service';

/**
 * Technical support device part processor.
 * 
 * @author Juan Godínez
 */
@Injectable({
  providedIn: 'root'
})
export class PartePsService extends BaseProcess<ParteRestcService>{

  constructor(httpClient: HttpClient,
    crest: ParteRestcService) { 
      super(httpClient, crest);
  }


}