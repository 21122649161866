import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Message, MessageCodes, OperationMode, OperationScreenModeService } from '@axks/components';
import { MessageService } from 'primeng/api';
import { Reparacion } from 'src/app/api/solicitud/reparacion';
import { ReparacionPsService } from 'src/app/procs/solicitud/reparacion-ps.service';

@Component({
  selector: 'axks-reparacion-cat',
  templateUrl: './reparacion-cat.component.html',
  styleUrls: ['./reparacion-cat.component.scss']
})
export class ReparacionCatComponent implements OnInit, OnChanges {

  catalog: Reparacion[];
  virtualScroll: boolean = false;
  showForm: boolean = false;
  newItem: Reparacion;

  @Input() value: Reparacion;
  /**
   * Habilita la funcionalidad para agregar
   */
  @Input() addNewEnable: boolean = false;

  /** 
   * Indica si hay que tomar en cuenta el modo de operación 
   * de la pantalla.
   */
   @Input() operationMode: OperationMode;

  /**
   * Bandera para habilitar la funcionalidad para agregar
   * un nuevo registro según los modos de operación de la
   */
  addNewToCat: boolean = false;

  @Input() idTipoEquipo: number;

  @Output() onChange = new EventEmitter<Reparacion>();

  constructor(private ReparacionPs: ReparacionPsService,
    private messages: MessageService,
    private route: ActivatedRoute,
    screenModeService: OperationScreenModeService) {

  }

  ngOnInit(): void {
    if (!this.value) {
      this.value = {} as Reparacion;
    }

    if (!this.addNewEnable) {
      this.addNewEnable = false;
    }

    if(this.operationMode){
      this.enableAddNewButton();
    }

    // Temporal mientras se desarrolla
    /* this.route.data.subscribe((data) => {
      this.addNewEnable = data.addNewEnable;
      this.idTipoEquipo = data.idTipoEquipo;
      this.populate();
    }) */

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.populate();
  }

  enableAddNewButton(){
    switch(this.operationMode){
      case OperationMode.ADDNEW:
      case OperationMode.EDIT:
        this.addNewEnable = true;
        break;
      default:
        this.addNewEnable = false;
        break;
    }
  }

  populate() {
    let filter = {} as Reparacion;

    if (this.idTipoEquipo) {
      filter.parte = {
        idTipoEquipo: this.idTipoEquipo
      }
    }

    this.virtualScroll = false;
    this.ReparacionPs.find(filter).subscribe(
      (data) => {
        this.catalog = data as Reparacion[];
        if (this.catalog.length > 10) {
          this.virtualScroll = true;
        }
      },
      (error) => {
        try {

          let message = error.error as Message;
          this.processMessage(message);
        } catch (e) {
          console.debug("Error")
          console.debug(error)
        }
      }
    );
  }

  processMessage(message: Message) {
    let messText: string;
    let sevMes = "info";
    switch (message.code) {
      case MessageCodes.NOT_FOUND_RESULTS.valueOf():
        messText = "No se localizaron Reparacions en el catálogo. Deberá registrarlas primero.";
        break;
      default:
        messText = "Error desconocido.";
        sevMes = "warn"
        console.debug(message);
        break;
    }

    this.messages.add({ severity: sevMes, summary: "Catálogo de partes de equipo", detail: messText });
  }

  selectOption(event, value) {
    let sel = this.catalog.find(
      (citem)=>citem.id == value.id);
    
    this.onChange.emit(sel);

  }

  onAddNewClick(event) {
    this.operationMode = OperationMode.ADDNEW;
    this.showForm = true;
    this.newItem = { parte: {} } as Reparacion;
  }

  onSaveClick(event) {
    this.ReparacionPs.register(this.newItem).subscribe(
      (data) => {
        this.populate();
        this.showForm = false;
        this.newItem = undefined;
      },(error) => {
        try {
          let message = error.error as Message;
          this.processMessage(message);
        } catch (e) {
          console.debug("Error")
          console.debug(error)
        }
        this.showForm = false;
        this.newItem = undefined;
      });
  }

  onCancelClick(event) {
    this.showForm = false;
    this.newItem = undefined;
  }
}
