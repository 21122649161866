import { Component, OnInit } from '@angular/core';

import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Estatus } from '../../../api/estatus';
import { EstatusPsService } from '../../../procs/estatus/estatus-ps.service';

/**
 * Main component for technical support status.
 * 
 * @author
 */
@Component({
  selector: 'axks-estatus',
  templateUrl: './estatus.component.html',
  styleUrls: ['./estatus.component.scss'],
  providers: [MessageService]
})
export class EstatusComponent extends BaseMainComponent<EstatusPsService> implements OnInit {
  

  constructor(protected deviceService: DeviceDetectorService, 
    protected messageService: MessageService, 
    protected screenModeService: OperationScreenModeService, 
    protected sessionProvier: SessionClientProvider,
    private psService: EstatusPsService) {
      super(deviceService, messageService, screenModeService, sessionProvier);
     }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initEmptyItem(): ComponentItem {
    return {
      item: {} as Estatus,
      minimumItem: {} as Estatus,
      registerItem: {} as Estatus
    } as ComponentItem;
  }

  getService(): EstatusPsService {
    return this.psService;
  }
  getColListDefinition(): ColumnDefinition[] {
    return [
      {header:'Clave', field:'id', inDetail:true, inResultList:true},
      {header:'Estatus', field:'estatus', inAll:true},
      

    ] as ColumnDefinition[];
  }
  getIdOfSelectedItem(): number {
    return this.item.minimumItem.id || this.item.item.id;
  }
  getComponentTitle(): string {
    return "Estatus";
  }
}
