import { Component, OnInit } from '@angular/core';

import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ButtonModel } from '@axks/components/lib/api/views/button-model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Modelo } from '../../../api/modelo';
import { ModeloPsService } from '../../../procs/modelo/modelo-ps.service';

/**
 * Main component for technical support device trademarks model.
 * 
 * @author
 */
@Component({
  selector: 'axks-modelo',
  templateUrl: './modelo.component.html',
  styleUrls: ['./modelo.component.scss'],
  providers: [MessageService]
})
export class ModeloComponent extends BaseMainComponent<ModeloPsService> implements OnInit {

  extraButtons: ButtonModel[];

  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private psService: ModeloPsService) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    this.extraButtons = [];
    super.ngOnInit();
  }

  initEmptyItem(): ComponentItem {
    return { 
      item: { marca: {tipoEquipo:{}} } as Modelo,
      minimumItem: { marca: {tipoEquipo:{}} } as Modelo,
      registerItem: { marca: {tipoEquipo:{}} } as Modelo,
    } as ComponentItem
  }

  getService(): ModeloPsService {
    return this.psService;
  }
  getColListDefinition(): ColumnDefinition[] {
    return [
      { header: 'Clave', field: 'idModelo', inDetail: true, inResultList: true },
      { header: 'Modelo', field: 'modelo', inAll: true },
      { header: 'Marca', field: 'marca.marca', inAll: true },
      { header: 'Tipo de Equipo', field: 'marca.tipoEquipo.tipoEquipo', inAll:true}


    ] as ColumnDefinition[];
  }
  getIdOfSelectedItem(): number {
    return this.item.minimumItem.idModelo || this.item.item.idModelo;
  }
  getComponentTitle(): string {
    return "Modelo de dispositivo";
  }
}

