import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Asignacion, Equipo, RecepcionEquipo } from 'src/app/api/equipo';
import { Tecnico } from 'src/app/api/tecnico';
import { AsignaPsService } from 'src/app/procs/equipo/asigna-ps.service';

@Component({
  selector: 'axks-recibe-dialog',
  templateUrl: './recibe-dialog.component.html',
  styleUrls: ['./recibe-dialog.component.scss']
})
export class RecibeDialogComponent extends BaseView implements OnInit {
  
  @Input() display: boolean = false;
  @Input() equipo: Equipo;
  @Output() onHide = new EventEmitter<any>();

  item: RecepcionEquipo;
  isLoading: boolean;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private asignaPs: AsignaPsService
  ) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {

    if (this.equipo) {
      let profile = this.getProfile() as ProfileC;
      let tec = profile["tecnico"] as Tecnico;
      this.item = {
        idEquipo: this.equipo.id,
        idTecnicoRecibe: tec ? tec.id : null,

      } as RecepcionEquipo;
    } else {
      this.item = {} as RecepcionEquipo;
    }
  }

  dialogSize(): any {
    if (this.isMobile()) {
      return { width: '90vw', height: '90%' };
    }

    return { width: '60vw', height: '50%' };
  }

  gridSize(): string {
    if (this.isMobile()) {
      return 'height:76vh';
    }

    return 'height:46vh';
  }

  onHideDialog(event) {
    this.onHide.emit();
  }

  onCancelar($event) {
    this.display = false;
  }

  onRecibirEquipo($event) {
    let histo = this.equipo.historico as Asignacion[];
    let asignacionActual = histo.find(asignacion => !asignacion.fecRecibe);
    this.item.idAsignacion = asignacionActual.idAsignaEquipo;

    this.isLoading = true;
    this.asignaPs.recepcionEquipo(this.item).subscribe(
      (data) => {
        this.display = false;
        this.isLoading = false;
      },
      (error) => {
        this.errorMessage("Error. Recepción de equipo.", "No se pudo procesar la solicitud");
        this.isLoading = false;
      }
    )

  }

}
