import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Seguimiento } from 'src/app/api/solicitud/solicitud';

@Component({
  selector: 'axks-det-historia',
  templateUrl: './det-historia.component.html',
  styleUrls: ['./det-historia.component.scss']
})
export class DetHistoriaComponent extends BaseView implements OnInit {

  @Input() historico: Seguimiento[];
  @Input() modoSoporte: boolean;
  @Input() fecFinSolicitud: string;
  @Input() idTipoSolicitud: number;

  displayDialog: boolean = false;
  selSeguimiento: Seguimiento;
  selectorEstilo: string;

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider
  ) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.historico) {
      this.historico = [];
    }

    this.selectorEstilo = "estilo-cursor-fuera-elemento";
  }

  esTecnico(): boolean {
    let profile = this.getProfile() as ProfileC;

    return profile["tecnico"] ? true : false;
  }

  onSelSeguimiento(event, seguimiento: Seguimiento) {
    this.selSeguimiento = seguimiento;
    this.displayDialog = true;
  }

  dialogStyle(): any {
    if (this.isMobile()) {
      return { width: '100%' };
    }
    else if (this.isTablet()) {
      return { width: '75%' };
    } else {
      return { width: '50%' };
    }
  }

  onFinAccion(event) {
    this.displayDialog = false;
    this.selSeguimiento = undefined;
  }

  funcionMouseOver(event) {
    this.selectorEstilo = "estilo-cursor-sobre-elemento";
  }

  funcionMouseOut(event) {
    this.selectorEstilo = "estilo-cursor-fuera-elemento";
  }

}
