import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { TipoSolicitud } from 'src/app/api/tipo-solicitud';


@Component({
  selector: 'axks-tipo-solicitud-form',
  templateUrl: './tipo-solicitud-form.component.html',
  styleUrls: ['./tipo-solicitud-form.component.scss']
})
export class TipoSolicitudFormComponent extends BaseView implements OnInit {

  @Input() item: TipoSolicitud = {};


  constructor(protected screenModeService: OperationScreenModeService, 
    protected deviceService: DeviceDetectorService, 
    protected messageService: MessageService, 
    protected sessionProvier: SessionClientProvider) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if(!this.item){
      this.item = {}as TipoSolicitud;
    }
  }

}
