import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { Observable } from 'rxjs';
import { EmpleadoNombre } from 'src/app/api/empleado';
import { EmpleadoRestcService } from 'src/app/client/empleado/empleado-restc.service';

@Injectable({
  providedIn: 'root'
})
export class EmpleadoPsService extends BaseProcess<EmpleadoRestcService>{

  client: EmpleadoRestcService;

  constructor(httpClient: HttpClient, crest: EmpleadoRestcService) {
    super(httpClient, crest);
    this.client = crest;
  }

  empleadosContratoActivo(idEmpresa: number): Observable<EmpleadoNombre[]> {
    let data = this.client.empleadosContratoActivo(idEmpresa);
    return data;
  }
}
