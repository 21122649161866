<br>
<span class="p-float-label">
    <p-button icon="pi pi-plus" *ngIf="addNewEnable && !showForm" (click)="onAddNewClick($event)" class="input-button">
    </p-button>
    <p-dropdown inputId="fl-tpeq" [(ngModel)]="value.id" [options]="catalog" optionLabel="tipoEquipo" optionValue="id"
        [autoDisplayFirst]="false" [virtualScroll]="virtualScroll" itemSize="10" [filter]="true" filterBy="tipoEquipo"
        (onChange)="selectOption($event,value)" autoWidth="false"
        [styleClass]="addNewEnable && !showForm ? 'with-button':''"></p-dropdown>
    <label for="fl-tpeq">Tipo de equipo</label>
</span>


<!-- <p-panel class="cat-form-popup" header="Nuevo tipo de equipo" *ngIf="showForm"> -->
<p-dialog [(visible)]="showForm" header="Nuevo tipo de equipo" [modal]="true" [draggable]="false" [closable]="true"
    contentStyleClass="dialog-content-style" [style]="{'width':'50%', 'height':'auto'}">

    <axks-tipo-equipo-form [item]="newItem" [formFields]="[]" [operationMode]="operationMode" [class]="'cmp-form-' + 1">
    </axks-tipo-equipo-form>
    <ng-template pTemplate="footer">

        <p-button label="Guardar" icon="pi pi-save" iconPos="left" (click)="onSaveClick($event)"></p-button>
        &nbsp;
        <p-button label="Cancelar" icon="pi pi-times" iconPos="left" (click)="onCancelClick($event)"></p-button>
    </ng-template>
</p-dialog>
<!-- </p-panel> -->
<p-toast></p-toast>