<p-card class="crd-accion-seg" *ngIf="idTipoSolicitud == tipoSolicitud.REVISION_EQUIPO">

    <ng-container *ngIf="seguimiento.estatus.idEstatus == estatusSolicitud.PROGRAMAR_RECEPCION_EQUIPO">
        <div class="p-grid">
            <div class="p-col-12">
                <p>
                    El equipo de soporte técnico necesita tener físicamente el equipo para su revisión.
                    Favor de indicar el día que se llevara el equipo para su revisión.
                </p>
            </div>
            <div class="p-col-12">
                <textarea id="fl-coment" pInputTextarea [(ngModel)]="comentario" [rows]="3" [cols]="40"
                    [autoResize]="false" [style]="{'width':'100%'}"
                    placeholder="Comentario para el equipo de soporte técnico"></textarea>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <p-button label="Programar recepción del equipo" [loading]="isLoading" (onClick)="onProgramaRecepcionEquipo()"></p-button>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="seguimiento.estatus.idEstatus == estatusSolicitud.EQUIPO_ENTREGADO_EMPLEADO">
        <div class="p-grid">
            <div class="p-col-12">
                <p>
                    Si el equipo se atendio de manera correcta favor de finalizar la solicitud. Si no se finaliza, la
                    solicitud
                    se cerrara automaticamente una semana despues de ser atendida.
                    En caso de requerir atención y si la solicitud ya se cerro, tendra que abrir otra solicitud.
                </p>
            </div>
        </div>

        <ng-template pTemplate="footer">
            <p-button label="Finalizar solicitud" [loading]="isLoading" (onClick)="onSolEquipoEntregadoAct()"></p-button>
        </ng-template>
    </ng-container>

</p-card>

<!-- </ng-container> -->
<p-toast></p-toast>