import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { Message, MessageCodes, OperationMode } from '@axks/components';
import { MessageService } from 'primeng/api';
import { TipoEquipo } from 'src/app/api/equipo';
import { TipoEquipoPsService } from 'src/app/procs/tipo-equipo/tipo-equipo-ps.service';

@Component({
  selector: 'axks-tipo-equipo-cat',
  templateUrl: './tipo-equipo-cat.component.html',
  styleUrls: ['./tipo-equipo-cat.component.scss']
})
export class TipoEquipoCatComponent implements OnInit, OnChanges {

  catalog: TipoEquipo[];

  /* @Input() value?: TipoEquipo; */
  @Input() value?: TipoEquipo;
  @Input() addNewEnable: boolean;
  @Input() operationMode: OperationMode;

  @Output() onChange = new EventEmitter<number>();

  showForm: boolean = false;
  virtualScroll : boolean = false;
  newItem: TipoEquipo;

  constructor(private tipoEquipoPs: TipoEquipoPsService,
    private messages: MessageService) { 
    
  }
  

  ngOnInit(): void {
    if(!this.value){
      this.value = {} as TipoEquipo;
    }

    if (this.operationMode) {
      this.enableAddNewButton();
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.populate();
    if (this.operationMode) {
      this.enableAddNewButton();
    }
  }

  enableAddNewButton() {
    switch (this.operationMode) {
      case OperationMode.ADDNEW:
        this.addNewEnable = true;
        break;
      case OperationMode.EDIT:
        this.addNewEnable = true;
        break;
      default:
        this.addNewEnable = false;
        break;
    }
  }

  populate() {
    let filter = {} as TipoEquipo;
    this.virtualScroll = false;
    this.tipoEquipoPs.find(filter).subscribe(
      (data) => {
        this.catalog = data as TipoEquipo[];
        if(this.catalog.length>10){
          this.virtualScroll = true;
        }
      },
      (error) => {
        try {

          let message = error.error as Message;
          this.processMessage(message);
        } catch (e) {
          console.debug("Error")
          console.debug(error)
        }
      }
    );
  }

  processMessage(message: Message) {
    let messText: string;
    let sevMes = "info";
    switch (message.code) {
      case MessageCodes.NOT_FOUND_RESULTS.valueOf():
        messText = "No se localizaron tipos de equipo. Deberá registrarlos primero.";
        break;
      default:
        messText = "Error desconocido.";
        sevMes = "warn"
        console.debug(message);
        break;
    }

    this.messages.add({severity:sevMes, summary: "Catálogo de tipos de equipo", detail: messText});
  }

  selectOption(event, value){
    this.onChange.emit(value.id);
  }

  onAddNewClick(event) {
    this.operationMode = OperationMode.ADDNEW;
    this.showForm = true;
    this.newItem = { } as TipoEquipo;
  }

  onSaveClick(event) {
    this.tipoEquipoPs.register(this.newItem).subscribe(
      (data) => {
        this.populate();
        this.showForm = false;
        this.newItem = {};
      }, (error) => {
        try {
          let message = error.error as Message;
          this.processMessage(message);
        } catch (e) {
          console.debug("Error")
          console.debug(error)
        }
        this.showForm = false;
        this.newItem = {};
      });
  }

  onCancelClick(event) {
    this.showForm = false;
    this.newItem = {};
  }

}
