import { Component, OnInit } from '@angular/core';

import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { TipoSolicitud } from '../../../api/tipo-solicitud';
import { TipoSolicitudPsService } from '../../../procs/tipo-solicitud/tipo-solicitud-ps.service';


/**
 * Main component for technical support requirement type.
 * 
 * @author
 */
@Component({
  selector: 'axks-tipo-solicitud',
  templateUrl: './tipo-solicitud.component.html',
  styleUrls: ['./tipo-solicitud.component.scss'],
  providers: [MessageService]
})
export class TipoSolicitudComponent extends BaseMainComponent<TipoSolicitudPsService> implements OnInit {


  constructor(protected deviceService: DeviceDetectorService, 
    protected messageService: MessageService, 
    protected screenModeService: OperationScreenModeService, 
    protected sessionProvier: SessionClientProvider,
    private psService: TipoSolicitudPsService) {
      super(deviceService, messageService, screenModeService, sessionProvier);

  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initEmptyItem(): ComponentItem {
    return {
      item: {} as TipoSolicitud,
      minimumItem: {} as TipoSolicitud,
      registerItem: {} as TipoSolicitud
    } as ComponentItem;
  }

  getService(): TipoSolicitudPsService {
    return this.psService;
  }
  getColListDefinition(): ColumnDefinition[] {
    return [
      { header: 'Clave', field: 'idTipoSolicitud', inDetail: true, inResultList: true },
      { header: 'TipoSolicitud', field: 'tipoSolicitud', inAll: true },


    ] as ColumnDefinition[];
  }
  getIdOfSelectedItem(): number {
    return this.item.minimumItem.idTipoSolicitud || this.item.item.idTipoSolicitud;
  }
  getComponentTitle(): string {
    return "TipoSolicitud";
  }

  
}
