<p-card class="crd-accion-seg" *ngIf="idTipoSolicitud == TP_SOLICITUD.INSTALACION_SOFTWARE">
    <ng-container
        *ngIf="seguimiento.estatus.idEstatus == ESTATUS.INGRESADA || seguimiento.estatus.idEstatus == ESTATUS.RECIBIDA">
        <div class="p-grid">
            <div class="p-col-12">
                <p>Describir los softwares y/o las licencias instaladas.</p>
            </div>
            <div class="p-col-12">
                <span class="p-float-label">
                    <textarea id="fl-coment" pInputTextarea [(ngModel)]="comentario" [rows]="3" [cols]="40"
                        [autoResize]="false" [style]="{'width':'100%'}"></textarea>
                    <label for="fl-coment">Descripción</label>
                </span>
            </div>
        </div>

        <ng-template pTemplate="footer">
            <p-button *ngIf="tecnico.id" label="Guardar" icon="pi pi-save" [loading]="isLoading"
                (onClick)="onSolicitudRecibidaAct($event)">
            </p-button>
        </ng-template>
    </ng-container>
</p-card>

<p-toast></p-toast>