<p-card class="crd-accion-seg" *ngIf="idTipoSolicitud == tipoSolicitud.REVISION_EQUIPO">

    <!-- RECIBIDA -->
    <ng-container *ngIf="seguimiento.estatus.idEstatus == ESTATUS.INGRESADA || seguimiento.estatus.idEstatus == ESTATUS.RECIBIDA">
        <div class="p-grid">
            <div class="p-col-12">
                <p>Solicitar al empleado que indique el día que traerá el equipo para su revisión.</p>
            </div>
            <!-- <div class="p-col-12">
                <textarea id="fl-coment" pInputTextarea [(ngModel)]="comentario" [rows]="3" [cols]="40"
                    [autoResize]="false" [style]="{'width':'100%'}" placeholder="Comentario"></textarea>
            </div> -->
        </div>

        <ng-template pTemplate="footer">
            <p-button label="Solicitar equipo" icon="pi pi-desktop" [loading]="isLoading" (onClick)="onSolicitudRecibidaAct($event)"></p-button>
        </ng-template>
    </ng-container>

    <!-- ESPERA_EQUIPO -->
    <ng-container *ngIf="seguimiento.estatus.idEstatus == ESTATUS.ESPERA_EQUIPO">
        <div class="p-grid">
            <div class="p-col-12">
                <p> Describir el estado del equipo al momento de recibirlo.</p>
            </div>
            <div class="p-col-12">
                <textarea id="fl-coment" pInputTextarea [(ngModel)]="comentario" [rows]="3" [cols]="40"
                    [autoResize]="false" [style]="{'width':'100%'}" placeholder="Comentario"></textarea>
            </div>
        </div>

        <ng-template pTemplate="footer">
            <p-button label="Recibir equipo" [loading]="isLoading" (onClick)="onEsperaEquipoAct($event)"></p-button>
        </ng-template>
    </ng-container>

    <!-- EQUIPO_RECIBIDO -->
    <ng-container *ngIf="seguimiento.estatus.idEstatus == ESTATUS.EQUIPO_RECIBIDO">
        <div class="p-grid">
            <div class="p-col-12">
                <p>Notificar al empleado que el equipo está en revisión.</p>
            </div>
            <div class="p-col-12">
                <textarea id="fl-coment" pInputTextarea [(ngModel)]="comentario" [rows]="3" [cols]="40"
                    [autoResize]="false" [style]="{'width':'100%'}" placeholder="Comentario"></textarea>
            </div>
        </div>

        <ng-template pTemplate="footer">
            <p-button label="En revisión" [loading]="isLoading" (onClick)="onEquipoRecibidoAct($event)"></p-button>
        </ng-template>
    </ng-container>

    <!-- EN REVISION -->
    <ng-container *ngIf="seguimiento.estatus.idEstatus == ESTATUS.EN_REVISION">
        <p>Registra el diagnóstico o resultado de la revisión del equipo realizando lo siguiente:
        </p>
        <ul>
            <li> Agrega las fallas que detectaste. <i class="pi pi-info-circle"
                    [pTooltip]="labelPs.getHelpByKey('registraFalla')" style="font-size: 90%"></i></li>
            <li> Agrega un comentario descriptivo.</li>
        </ul>
        <!-- {{fallaDiag | json}}
        {{diagnostico | json}} -->
        <div class="p-grid">
            <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                <axks-falla-cat [value]="fallaDiag.falla" [idTipoEquipo]="seguimiento.idTipoEquipo"
                    [addNewEnable]="true" (onChange)="onFallaSel($event)"></axks-falla-cat>
            </div>
            <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                <br>
                <span class="p-float-label">
                    <textarea id="fl-coment" pInputTextarea [(ngModel)]="fallaDiag.comentario" [rows]="3" [cols]="40"
                        [autoResize]="false" [style]="{'width':'100%'}"></textarea>
                    <label for="fl-coment">Descipción de la falla</label>
                </span>
            </div>
            <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                <p-button label="Agregar al diagnóstico" (click)="onAgregaAlDiagnostico($event)"></p-button> &nbsp;
                <p-button label="Cancelar" (click)="onCancelarFallaDiagnostico($event)"></p-button>
            </div>

            <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                <p-panel>
                    <ng-template pTemplate="header">
                        Fallas del diagnóstico.
                    </ng-template>
                    <div class="p-grid">
                        <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12" *ngFor="let diag of diagnostico"
                            style="overflow-wrap: break-word;">
                            <!-- {{diag | json}} -->
                            <i class="pi pi-minus-circle" pTooltip="Quitar de la lista"
                                (click)="onQuitarFallaDelDiagnostico($event, diag.falla.id, diag)"></i> &nbsp;
                            {{diag.falla.parte.parte}} - {{diag.falla.falla}} {{diag.comentario}}

                        </div>
                    </div>
                </p-panel>
            </div>
        </div>

        <ng-template pTemplate="footer">
            <div class="p-grid" *ngIf="showGuardarDiagnostico">
                <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                    <br>
                    <span class="p-float-label">
                        <textarea id="fl-coment" pInputTextarea [(ngModel)]="comentario" [rows]="3" [cols]="40"
                            [autoResize]="false" [style]="{'width':'100%'}"></textarea>
                        <label for="fl-coment">Comentarios al diagnóstico</label>
                    </span>
                </div>
                <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12" style="display: flex; justify-content: right;">
                    <p-button label="Guardar diagnóstico" [loading]="isLoading" (onClick)="onEnRevisionAct($event)"></p-button>
                </div>
            </div>
        </ng-template>

    </ng-container>

    <!-- DIAGNOSTICO-->
    <ng-container *ngIf="seguimiento.estatus.idEstatus == ESTATUS.DIAGNOSTICO">
        <!-- {{repAtencion | json}} -->
        <!-- {{actividadesSol | json}} -->
        <p>Registra las actividades realizadas en la resolución de la solicitud realizando lo siguiente:
        </p>
        <ul>
            <li> Agrega las reparaciones realizadas. <i class="pi pi-info-circle"
                    [pTooltip]="labelPs.getHelpByKey('registraReparacion')" style="font-size: 90%"></i> </li>
            <li> Agrega un comentario descriptivo.</li>
        </ul>

        <div class="p-grid">
            <div class="p-sm-12 p-md-6 p-lg-6 p-xl-6 p-col-12">
                <axks-reparacion-cat [value]="repAtencion.reparacion" [idTipoEquipo]="seguimiento.idTipoEquipo"
                    [addNewEnable]="true" (onChange)="onReparacionSel($event)"></axks-reparacion-cat>
            </div>
            <div class="p-sm-12 p-md-6 p-lg-6 p-xl-6 p-col-12">
                <br>
                <span class="p-float-label">
                    <p-dropdown inputId="fl-repItem" [autoDisplayFirst]="false" [options]="catFallas"
                        optionLabel="falla" [style]="{'width':'100%'}" [(ngModel)]="repAtencion.falla"></p-dropdown>
                    <label for="fl-repItem">Falla</label>
                </span>
            </div>
            <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                <br>
                <span class="p-float-label">
                    <textarea id="fl-coment" pInputTextarea [(ngModel)]="repAtencion.observacion" [rows]="3" [cols]="40"
                        [autoResize]="false" style="width: 100%;"></textarea>
                    <label for="fl-coment">Descipción de la reparación</label>
                </span>
            </div>
            <div class="p-sm-12 p-md-6 p-lg-6 p-xl-6 p-col-12">
                <br>
                <span class="p-float-label">
                    <p-inputNumber inputId="fl-costo" [(ngModel)]="repAtencion.costo" mode="currency" currency="MXN"
                        locale="es-MX" [maxlength]="14" [minFractionDigits]="2" [maxFractionDigits]="2"
                        [style]="{width: '100%'}"></p-inputNumber>

                    <label for="fl-costo">Costo</label>
                </span>
            </div>
            <div class="p-sm-12 p-md-6 p-lg-6 p-xl-6 p-col-12">
                <br>
                <span class="p-float-label">
                    <input type="text" inputId="fl-idComp" [(ngModel)]="repAtencion.idCompra" pInputText
                        pKeyFilter="int" [style]="{width: '100%'}">
                    <label for="fl-idComp">ID de compra (Opcional)</label>
                </span>
            </div>
            <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                <p-button label="Agregar a las reparaciones" (click)="onAgregaReparacionEnAtenciones($event)">
                </p-button> &nbsp;
                <p-button label="Cancelar" (click)="onCancelarRepEnAtenciones($event)"></p-button>
            </div>

            <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                <p-panel>
                    <ng-template pTemplate="header">
                        Reparaciones de la solicitud.
                    </ng-template>
                    <div class="p-grid">
                        <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12" *ngFor="let activ of actividadesSol"
                            style="overflow-wrap: break-word;">
                            <i class="pi pi-minus-circle" pTooltip="Quitar de la lista"
                                (click)="onQuitarReparacionDeAtenciones($event, activ.reparacion.id, activ)"></i>
                            &nbsp;
                            <span>Falla: {{activ.falla.falla}} - </span>
                            <span>Reparación: {{activ.reparacion.reparacion}} - </span>
                            <span *ngIf="activ.costo">{{activ.costo | currency}} - </span>
                            <span *ngIf="!activ.costo">$0.00 - </span>
                            <span *ngIf="activ.idCompra">No. Compra: {{activ.idCompra}} - </span>{{activ.observacion}}
                        </div>
                    </div>
                </p-panel>
            </div>
        </div>

        <ng-template pTemplate="footer">
            <div class="p-grid" *ngIf="showGuardarAtencion">
                <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                    <br>
                    <span class="p-float-label">
                        <textarea id="fl-coment" pInputTextarea [(ngModel)]="comentario" [rows]="3" [cols]="40"
                            [autoResize]="false" style="width: 100%;"></textarea>
                        <label for="fl-coment">Comentarios a la atención de la solicitud</label>
                    </span>
                </div>
                <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12" style="display: flex; justify-content: right;">
                    <p-button label="Guardar atención" [loading]="isLoading" (onClick)="onEnDiagnosticoAct($event)"></p-button>
                </div>
            </div>
        </ng-template>
    </ng-container>

    <!-- ATENDIDA -->
    <ng-container *ngIf="seguimiento.estatus.idEstatus == ESTATUS.ATENDIDA">
        <div class="p-grid">
            <div class="p-col-12">
                <p>Cuando se entregue el equipo atendido favor de marcarlo en la solicitud para que el empleado pueda
                    finalizar la solicitud.</p>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <p-button label="Equipo entregado" [loading]="isLoading" (onClick)="onSolAtendidaAct()"></p-button>
        </ng-template>
    </ng-container>

</p-card>

<p-toast key="msgErroDiag"></p-toast>
<p-toast key="msgErrorAtenc"></p-toast>