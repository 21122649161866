<!-- <div>
{{item | json}}
</div> -->

<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('caracteristica')">
        <br>
        <span class="p-float-label">
            <input id="fl-caract" type="text" pInputText [(ngModel)]="item.caracteristica">
            <label for="fl-caract">Característica</label>
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('tpEquipo')">
        <br>
        <span class="p-float-label">
            <p-dropdown inputId="fl-tpeq" [(ngModel)]="item.idTipoEquipo" [options]="catTipoEquipo" optionLabel="tipoEquipo"
                [filter]="true" filterBy="tipoEquipo" optionValue="id"
                [autoDisplayFirst]="false" autoWidth="false" >
            </p-dropdown>
            <label for="fl-tpeq">Tipo de equipo</label>
        </span>
    </div>
</div>