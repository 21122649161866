import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseView, Message, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { EstatusSolicitud, TipoSolicitud } from 'src/app/api/enums/solicitud.enum';
import { Seguimiento, SeguimientoReg } from 'src/app/api/solicitud/solicitud';
import { SolicitudRestcService } from 'src/app/client/solicitud/solicitud-restc.service';
import { SeguimientoService } from 'src/app/procs/solicitud/observable/seguimiento.service';
import { SolicitudPsService } from 'src/app/procs/solicitud/solicitud-ps.service';

@Component({
  selector: 'axks-accion-seguimiento',
  templateUrl: './accion-seguimiento.component.html',
  styleUrls: ['./accion-seguimiento.component.scss']
})
export class AccionSeguimientoComponent extends BaseView implements OnInit {

  estatusSolicitud = EstatusSolicitud;
  tipoSolicitud = TipoSolicitud;

  @Input() seguimiento: Seguimiento;
  @Input() idTipoSolicitud: number;

  comentario: string;
  isLoading: boolean = false;

  @Output() onFinAccion = new EventEmitter();

  constructor(
    protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private solPs: SolicitudPsService,
    private segObs: SeguimientoService
  ) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.seguimiento) {
      this.seguimiento = {};
    }

    this.isLoading = false;
  }

  onProgramaRecepcionEquipo() {

    this.isLoading = true;

    let seg = {} as SeguimientoReg;
    seg.idSegSolicitud = this.seguimiento.idSegSolicitud;
    seg.idSolicitud = this.seguimiento.idSolicitud;
    seg.comentario = this.comentario;

    this.solPs.solEquipoParaRevisionAct(seg).subscribe(
      (data) => {
        let mess = data as Message;
        this.segObs.recargaHistoria.next(true);
        this.infoMessage("Seguimiento", mess.details);
        this.isLoading = false;
        this.onFinAccion.emit(true);
      },
      (error) => {
        this.isLoading = false;
        this.errorMessage("Error", "No se pudo avanzar la solicitud");
      }
    );
  }

  onSolEquipoEntregadoAct() {

    this.isLoading = true;

    let seg = {} as SeguimientoReg;
    seg.idSegSolicitud = this.seguimiento.idSegSolicitud;
    seg.idSolicitud = this.seguimiento.idSolicitud;

    this.solPs.solEquipoEntregadoAct(seg).subscribe(
      (data) => {
        let mess = data as Message;
        this.segObs.recargaHistoria.next(true);
        this.infoMessage("Seguimiento", mess.details);
        this.isLoading = false;
        this.onFinAccion.emit(true);
      },
      (error) => {
        this.isLoading = false;
        this.errorMessage("Error", "No se pudo avanzar la solicitud");
      }
    );
  }

}
