import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { ActionsBarModule, DetailTabModule, OperationsBarModule, ResultListModule, SessionClientProvider } from '@axks/components';
import { ToastModule } from 'primeng/toast';
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';
import { StepsModule } from 'primeng/steps';
import { ButtonModule } from 'primeng/button';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CalendarModule } from 'primeng/calendar';
import { MessageService } from 'primeng/api';
import { EstatusComponent } from './views/estatus/estatus/estatus.component';
import { EstatusFormComponent } from './views/estatus/estatus-form/estatus-form.component';
import { MarcaFormComponent } from './views/marca/marca-form/marca-form.component';
import { ModeloFormComponent } from './views/modelo/modelo-form/modelo-form.component';
import { ParteFormComponent } from './views/parte/parte-form/parte-form.component';
import { TipoSolicitudFormComponent } from './views/tipo-solicitud/tipo-solicitud-form/tipo-solicitud-form.component';
import { MarcaComponent } from './views/marca/marca/marca.component';
import { ModeloComponent } from './views/modelo/modelo/modelo.component';
import { ParteComponent } from './views/parte/parte/parte.component';
import { TipoSolicitudComponent } from './views/tipo-solicitud/tipo-solicitud/tipo-solicitud.component';
import { MarcaCatComponent } from './views/marca/marca-cat/marca-cat.component';
import { EquipoComponent } from './views/equipo/equipo/equipo.component';
import { EquipoFormComponent } from './views/equipo/equipo-form/equipo-form.component';
import { ModeloCatComponent } from './views/modelo/modelo-cat/modelo-cat.component';
import { ListboxModule } from 'primeng/listbox';
import { DialogModule } from 'primeng/dialog';
import { TimelineModule } from 'primeng/timeline';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { InputTextareaModule } from 'primeng/inputtextarea';

import localeESMX from "@angular/common/locales/es-MX";
import { SolicitudesNuevasDashComponent } from './views/solicitud/solicitudes-nuevas-dash/solicitudes-nuevas-dash.component';
import { GrafDespMensualDashComponent } from './views/solicitud/graf-desp-mensual-dash/graf-desp-mensual-dash.component';
import { ChartModule } from 'primeng/chart';
import { HomeComponent } from './views/home/home.component';
import { environment } from 'src/environments/environment';
import { SolicitudFormComponent } from './views/solicitud/solicitud-form/solicitud-form.component';
import { SolicitudComponent } from './views/solicitud/solicitud/solicitud.component';
import { TipoEquipoFormComponent } from './views/tipo-equipo/tipo-equipo-form/tipo-equipo-form.component';
import { TipoEquipoComponent } from './views/tipo-equipo/tipo-equipo/tipo-equipo.component';
import { TipoEquipoCatComponent } from './views/tipo-equipo/tipo-equipo-cat/tipo-equipo-cat.component';
import { AsignaDialogComponent } from './views/equipo/asigna/asigna-dialog/asigna-dialog.component';
import { AsignaHistoriaComponent } from './views/equipo/asigna-historia/asigna-historia.component';
import { DetHistoriaComponent } from './views/solicitud/det-historia/det-historia.component';
import { DetSeguimientoComponent } from './views/solicitud/det-seguimiento/det-seguimiento.component';
import { AccionSeguimientoComponent } from './views/solicitud/accion-seguimiento/accion-seguimiento.component';
import { AccionTecSeguimientoComponent } from './views/solicitud/accion-tec-seguimiento/accion-tec-seguimiento.component';
import { FallaFormComponent } from './views/solicitud/falla/falla-form/falla-form.component';
import { FallaComponent } from './views/solicitud/falla/falla/falla.component';
import { ParteCatComponent } from './views/parte/parte-cat/parte-cat.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FallaCatComponent } from './views/solicitud/falla/falla-cat/falla-cat.component';
import { TooltipModule } from 'primeng/tooltip';
import { ReparacionFormComponent } from './views/solicitud/reparacion/reparacion-form/reparacion-form.component';
import { ReparacionCatComponent } from './views/solicitud/reparacion/reparacion-cat/reparacion-cat.component';
import { ReparacionComponent } from './views/solicitud/reparacion/reparacion/reparacion.component';

import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputNumberModule } from 'primeng/inputnumber';
import { RecibeDialogComponent } from './views/equipo/recibe-dialog/recibe-dialog.component';
import { CaracteristicaFormComponent } from './views/caracteristica/caracteristica-form/caracteristica-form.component';
import { CaracteristicaComponent } from './views/caracteristica/caracteristica/caracteristica.component';
import { CaracteristicaCatComponent } from './views/caracteristica/caracteristica-cat/caracteristica-cat.component';
import { CaracteristicaEquipoComponent } from './views/equipo/caracteristica-equipo/caracteristica-equipo.component';
import { AccionGenerarCorreoComponent } from './views/solicitud/accion-generar-correo/accion-generar-correo.component';
import { AccionTecGenerarCorreoComponent } from './views/solicitud/accion-tec-generar-correo/accion-tec-generar-correo.component';
import { AccionTecLicenciaComponent } from './views/solicitud/accion-tec-licencia/accion-tec-licencia.component';
import { AccionTecSOInstalacionComponent } from './views/solicitud/accion-tec-s-o-instalacion/accion-tec-s-o-instalacion.component';
import { AccionLicenciaComponent } from './views/solicitud/accion-licencia/accion-licencia.component';
import { AccionSOComponent } from './views/solicitud/accion-s-o/accion-s-o.component';
import { TecnicoFormComponent } from './views/tecnico/tecnico-form/tecnico-form.component';
import { TecnicoComponent } from './views/tecnico/tecnico/tecnico.component';
import { KeyFilterModule } from 'primeng/keyfilter';
import { CheckboxModule } from 'primeng/checkbox';
import { FieldsetModule } from 'primeng/fieldset';
import { TabViewModule } from 'primeng/tabview';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

registerLocaleData(localeESMX, "es-MX");

@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    AppComponent,
    EstatusComponent,
    EstatusFormComponent,
    MarcaFormComponent,
    ModeloFormComponent,
    ParteFormComponent,
    TipoSolicitudFormComponent,
    MarcaComponent,
    ModeloComponent,
    ParteComponent,
    TipoSolicitudComponent,
    MarcaCatComponent,
    EquipoComponent,
    EquipoFormComponent,
    ModeloCatComponent,
    SolicitudesNuevasDashComponent,
    GrafDespMensualDashComponent,
    HomeComponent,
    SolicitudFormComponent,
    SolicitudComponent,
    TipoEquipoFormComponent,
    TipoEquipoComponent,
    TipoEquipoCatComponent,
    AsignaDialogComponent,
    AsignaHistoriaComponent,
    DetHistoriaComponent,
    DetSeguimientoComponent,
    AccionSeguimientoComponent,
    AccionTecSeguimientoComponent,
    FallaFormComponent,
    FallaComponent,
    ParteCatComponent,
    FallaCatComponent,
    ReparacionFormComponent,
    ReparacionCatComponent,
    ReparacionComponent,
    RecibeDialogComponent,
    CaracteristicaFormComponent,
    CaracteristicaComponent,
    CaracteristicaCatComponent,
    CaracteristicaEquipoComponent,
    AccionGenerarCorreoComponent,
    AccionTecGenerarCorreoComponent,
    AccionTecLicenciaComponent,
    AccionTecSOInstalacionComponent,
    AccionLicenciaComponent,
    AccionSOComponent,
    TecnicoFormComponent,
    TecnicoComponent
  ],
  imports: [
    FontAwesomeModule,
    TabViewModule,
    FieldsetModule,
    KeyFilterModule,
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    InputTextModule,
    DropdownModule,
    HttpClientModule,
    TableModule,
    ToolbarModule,
    ActionsBarModule,
    OperationsBarModule,
    ResultListModule,
    DetailTabModule,
    ToastModule,
    CardModule,
    PanelModule,
    StepsModule,
    ButtonModule,
    SelectButtonModule,
    CalendarModule,
    ListboxModule,
    ChartModule,
    DialogModule,
    TimelineModule,
    ScrollPanelModule,
    InputTextareaModule,
    ProgressSpinnerModule,
    TooltipModule,
    OverlayPanelModule,
    InputNumberModule,
    CheckboxModule
  ],
  providers: [MessageService, SessionClientProvider,
    { provide: "env", useValue: environment }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
