import { Injectable } from '@angular/core';

import { BaseProcess } from '@axks/components';
import { HttpClient } from '@angular/common/http';

import { EstatusRestcService } from 'src/app/client/estatus/estatus-restc.service';

/**
 * Technical support Status processor.
 * 
 * @author Juan Godínez
 */
@Injectable({
  providedIn: 'root'
})
export class EstatusPsService extends BaseProcess<EstatusRestcService>{

  constructor(httpClient: HttpClient,
    crest: EstatusRestcService) { 
      super(httpClient, crest)
    }

}