<!-- {{item | json}} -->
<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('marca')">
        <axks-tipo-equipo-cat [value]="item.marca.tipoEquipo"></axks-tipo-equipo-cat>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('marca') && item.marca.tipoEquipo.id">
        <axks-marca-cat [value]="item.marca" [idTipoEquipo]="item.marca.tipoEquipo.id"></axks-marca-cat>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('modelo')">
        <br>
        <span class="p-float-label">
            <input id="fl-modelo" type="text" pInputText [(ngModel)]="item.modelo">
            <label for="fl-modelo">Modelo</label>
        </span>
    </div>
    
</div>
