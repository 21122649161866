import { Injectable } from '@angular/core';

import { BaseProcess } from '@axks/components';
import { HttpClient } from '@angular/common/http';

import { ModeloRestcService } from 'src/app/client/modelo/modelo-restc.service';


/**
 * Technical support device model processor.
 * 
 * @author Juan Godínez
 */
@Injectable({
  providedIn: 'root'
})
export class ModeloPsService extends BaseProcess<ModeloRestcService>{

  constructor(httpClient: HttpClient,
    crest: ModeloRestcService) {
      super(httpClient,crest)
  }

}