import { Component, Input, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Caracteristica, CaracteristicaReg } from 'src/app/api/caracteristica';
import { CaracteristicaPsService } from 'src/app/procs/caracteristica/caracteristica-ps.service';

@Component({
  selector: 'axks-caracteristica',
  templateUrl: './caracteristica.component.html',
  styleUrls: ['./caracteristica.component.scss']
})
export class CaracteristicaComponent extends BaseMainComponent<CaracteristicaPsService> implements OnInit {

  constructor(protected deviceService: DeviceDetectorService, 
    protected messageService: MessageService, 
    protected screenModeService: OperationScreenModeService, 
    protected sessionProvier: SessionClientProvider,
    protected caracteristicaPs: CaracteristicaPsService) {

    super(deviceService, messageService, screenModeService, sessionProvier);
   }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {} as Caracteristica,
      minimumItem: {} as Caracteristica,
      registerItem: {} as CaracteristicaReg,
    } as ComponentItem;
    return empty;
  }
  getService(): CaracteristicaPsService {
    return this.caracteristicaPs;
  }
  getColListDefinition(): ColumnDefinition[] {
    return [
      {field: "id", header:'Clave', inResultList:true, inDetail:true } as ColumnDefinition,
      {field: 'caracteristica', header:'Caracteristica', inAll:true} as ColumnDefinition,
      {field: 'tipoEquipo', header: 'Tipo de equipo', inAll: true} as ColumnDefinition
    ] as ColumnDefinition[];
  }
  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }
  getComponentTitle(): string {
    return 'Caracteristica';
  }

  componentsFields() {
    super.componentsFields();
    this.formSearch = [
      "caracteristica"
    ];
  }

}
