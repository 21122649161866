<br>
<!-- {{newItem | json}} -->
<span class="p-float-label">
    <!-- [editable]="true" (keyup.enter)="onEnter($event)" -->
    <p-dropdown inputId="fl-caract" [(ngModel)]="value.idParte" 
    [options]="catalog" optionLabel="parte" optionValue="idParte"
    [autoDisplayFirst]="false" [virtualScroll]="virtualScroll" itemSize="10"
    [filter]="true" filterBy="tipoEquipo" 
    (onChange)="selectOption($event,value)"
    autoWidth="false" [style]="{'width':'80%'}"
    ></p-dropdown>
    <label for="fl-caract">Partes</label>
</span>
<p-button icon="pi pi-plus" iconPos="left" *ngIf="addNewEnable && !showForm" (click)="onAddNewClick($event)">
</p-button>

<p-panel class="cat-form-popup" *ngIf="showForm" header="Nueva Parte">

    <axks-parte-form [operationMode]="operationMode" [item]="newItem" [formFields]="[]" ></axks-parte-form>
    <p-button label="Guardar" icon="fa fa-disk" iconPos="left" (click)="onSaveClick($event)"></p-button>
    &nbsp;
    <p-button label="Cancelar" icon="fa fa-close" iconPos="left" (click)="onCancelClick($event)"></p-button>
</p-panel>

<p-toast></p-toast>
