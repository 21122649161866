<div class="p-grid">
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('idTipoEquipo')">
        <axks-tipo-equipo-cat [value]="item.parte.tipoEquipo" (onChange)="onChangeTipoEquipo($event)" ></axks-tipo-equipo-cat>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('parte') && item.parte.idTipoEquipo">
        <axks-parte-cat [value]="item.parte" [idTipoEquipo]="item.parte.idTipoEquipo" [addNewEnable]="true" [operationMode]="operationMode"></axks-parte-cat>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-2 p-xl-2 p-col-12" *ngIf="allowFormFields('falla')">
        <br>
        <span class="p-float-label">
            <input id="fl-parte" type="text" pInputText [(ngModel)]="item.falla">
            <label for="fl-parte">Falla</label>
        </span>
    </div>
</div>
