import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ButtonModel } from '@axks/components/lib/api/views/button-model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Tecnico, TecnicoReg } from 'src/app/api/tecnico';
import { TecnicoPsService } from 'src/app/procs/tecnico/tecnico-ps.service';

@Component({
  selector: 'axks-tecnico',
  templateUrl: './tecnico.component.html',
  styleUrls: ['./tecnico.component.scss']
})
export class TecnicoComponent extends BaseMainComponent<TecnicoPsService> implements OnInit {

  extraButtons: ButtonModel[];

  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private client: TecnicoPsService) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.extraButtons = [];
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {} as Tecnico,
      minimumItem: {} as Tecnico,
      registerItem: {} as TecnicoReg
    } as ComponentItem;
    return empty;
  }

  getService(): TecnicoPsService {
    return this.client;
  }
  getColListDefinition(): ColumnDefinition[] {
    let columns = [
      { field: 'id', header: 'ID Tecnico', inAll: true },
      { field: 'idEmpleado', header: 'ID Empleado', inDetail: true },
      { field: 'nombreCompleto', header: 'Nombre del empleado', inAll: true },
      { field: 'fecAsigna', header: 'Fecha de asignacion', inAll: true, formatFunction: this.formatoFecha },
      { field: 'fecBaja', header: 'Fecha de baja', inDetail: true, formatFunction: this.formatoFecha },
    ] as ColumnDefinition[];
    return columns;
  }
  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }
  getComponentTitle(): string {
    return 'Tecnicos';
  }

  formatoFecha(date: string): string {
    let pipe = new DatePipe("es-MX");
    return pipe.transform(date, "dd 'de' MMMM 'de' yyyy");
  }

  rowSelect(event): void {
    super.rowSelect(event);
    this.client.detail(event.id).subscribe(
      (data) => {
        let tecnico = data as Tecnico;
        if (tecnico.fecBaja == null || tecnico.fecBaja == "") {
          this.initBtnTecBaja();
        }
        else {
          this.disableBtnTecBaja();
        }
      }
    );
  }

  initBtnTecBaja() {
    let buttonBaja = {
      name: 'btnBajaTecnico',
      enable: true,
      label: 'Dar de baja',
      showLabel: true,
      onClick: this.darBajaTecnico.bind(this),
      showWhen: [OperationMode.SELECTED],
      position: 'left'
    } as ButtonModel;

    this.extraButtons = [buttonBaja];
  }

  disableBtnTecBaja() {
    this.extraButtons = [];
  }

  darBajaTecnico() {
    this.client.darBajaTecnico(this.getIdOfSelectedItem()).subscribe(
      (data) => {
        this.messageService.add({ severity: 'success', summary: 'Tecnico dado de baja', detail: 'El tecnico se ha dado de baja correctamente' });
        this.rowSelect(this.workItem());
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error al dar de baja al tecnico', detail: 'No se pudo dar de baja al tecnico' });
      }
    )
  }

}
